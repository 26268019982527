.featurePage {
  .feature_nav {
    width: 100%;
    background: $white;
    padding-top: 20px;
    margin-top: 72px;
    @include sp {
      margin-top: 30px;
    }
    &_inner {
      width: 90%;
      max-width: 960px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $gray;
      padding: 0 34px 10px;
      margin: 0 auto;
      @include sp {
        padding: 0 0 10px;
        flex-wrap: wrap;
      }
    }
    &_cmn {
      @include sp {
        width: 50%;
        margin-top: 12px;
        // &:nth-child(3+n){
        // }
      }
      a {
        @include fz(18);
        font-weight: bold;
        color: $black;
        display: flex;
        align-items: center;
        position: relative;
        @include sp {
          width: 100%;
          @include fz(16);
          justify-content: center;
        }
        &::before {
          content: "";
          display: inline-block;
          width: 22px;
          height: 22px;
          background: url(../img/feature/nav_icon_01.svg) center center /contain no-repeat $green;
          margin-right: 6px;
          border-radius: 50%;
        }
      }
      &:nth-child(2n){
        a::before {
          background: url(../img/feature/nav_icon_02.svg) center center /contain no-repeat $greenDark;
        }
      }
    }

    &.active {
      position: fixed;
      left: 0;
      top: 100px;
      margin-top: 0;
      padding-bottom: 14px;
      z-index: 1000;
      .feature_nav_inner {
        border-bottom: none;
      }
      @include sp {
        top: 8vh;
      }

      & + .cu-sec_inner {
        margin-top: calc(53px + 72px + 50px);
      }
    }
  }

  .feature_cont {
    margin-top: 50px;
    &_ttl {
      width: 100%;
      height: 54px;

      // background: $green;
      display: flex;
      align-items: flex-end;
      padding: 0 32px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      @include sp {
        padding: 0 16px;
      }
      img {
        width: auto;
        height: 100%;
      }
      span {
        display: inline-block;
        @include fz(15);
        color: $white;
        font-weight: 400;
        margin-bottom: 16px;
        margin-left: 8px;
      }
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: $green;
        position: absolute;
        top: 0;
        left: -100%;
        transition: .3s ease;
        z-index: -1;
      }

      &.action::before {
        left: 0;
      }
    }
    &_fig {
      position: relative;
      .voice {
        display: block;
        width: auto;
        height: 74px;
        position: absolute;
        @include sp {
          height: 12vw;
        }
        &.t-l {
          top: 36px;
          left: 52px;
          @include sp {
            top: 4vw;
            left: 4vw;
          }
        }
        &.b-l {
          bottom: 36px;
          left: 52px;
          @include sp {
            bottom: 4vw;
            left: 4vw;
          }
        }
        &.b-r {
          bottom: 36px;
          right: 52px;
          @include sp {
            bottom: 4vw;
            right: 4vw;
          }
        }
      }
    }
    &_txtArea {
      background: $greenLight;
      padding: 46px 0 58px;
      @include sp {
        padding: 38px 0 38px;
      }
      &_catch {
        @include fz(38);
        text-align: center;
        color: $greenDark;
        font-weight: bold;
        @include sp {
          @include fz(28);
        }
      }
      &_txt {
        @include fz(16);
        line-height: 2em;
        text-align: center;
        margin-top: 48px;
        @include sp {
          @include fz(14);
          padding: 0 10px;
          margin-top: 28px;
        }
      }
      &_list {
        max-width: 570px;
        width: 95%;
        background: #c6f0e6;
        border-radius: 14px;
        padding: 20px 30px;
        margin: 24px auto 0;
        @include sp {
          padding: 20px 14px;
        }
        &_cmn {
          @include fz(16);
          line-height: 1.8em;
          font-weight: bold;
          // display: flex;
          // align-items: center;
          position: relative;
          padding-left: 20px;
          & + .feature_cont_txtArea_list_cmn {
            margin-top: 10px;
          }
          @include sp {
            @include fz(14);
          }
          &::before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background: $green;
            border-radius: 50%;
            // margin-right: 4px;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(40%);
            @include sp {
              transform: translateY(26%);
            }
          }
        }
      }
    }

    &_cmn:nth-child(2n) {
      .feature_cont_ttl {
        // background: $greenDark;
        // background: linear-gradient(to right, $greenDark 100%, $white);
        &::before {
          background: $greenDark;
        }
      }
      .feature_cont_txtArea {
        background: #CCF2E9;
        &_list {
          background: #eefaf7;
        }
      }
    }

    &_inner {
      transition: .5s ease;
      opacity: 0;
      position: relative;
      top: -30px;
      &.action {
        opacity: 1;
        top: 0;
      }
    }
  }

  .demo {
    background: $yellowLight;
    border: 4px solid $yellow;
    padding: 42px 0 64px;
    margin-top: 100px;
    @include sp {
      padding: 24px 0 54px;
    }
    &_txt {
      @include fz(16);
      line-height: 1.8em;
      text-align: center;
      margin-top: 74px;
      @include sp {
        @include fz(14);
        text-align: left;
        padding: 0 10px;
        margin-top: 40px;
      }
    }
    &_btn {
      margin-top: 54px;
      @include sp {
        margin-top: 36px;
      }
    }
  }
}
