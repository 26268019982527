.newsSingle {
  padding-bottom: 70px;
  .mvSingle {
    width: 100vw;
    min-height: 250px;
    background: url(../img/news/news_single_mv.jpg) center center /cover no-repeat;
    padding: 36px 0;
    @include sp {
      width: 100%;
      min-height: 0;
    }
    &_inner {
      max-width: 960px;
      width: 90%;
      margin: 0 auto;
    }
    &_bread {
      border-bottom: 1px solid $grayBlack;
      padding: 0 18px 4px;
      @include sp {
        padding: 4px;
      }
    }
    &_tag {
      margin-left: auto;
      margin-right: 0;
    }
    &_date {
      @include fz(25);
      font-weight: bold;
    }
    &_ttl {
      @include fz(30);
      line-height: 1.5em;
      font-weight: bold;
      margin-top: 16px;
    }
    &_txtArea {
      width: 95%;
      margin: 16px auto 0;
    }
  }

  .newsSingle_cont {
    &_inner {
      @include fz(16);
      line-height: 1.8em;
      h2 {
        @include fz(30);
        line-height: 1.5em;
        color: $green;
        margin-top: 90px;
        margin-bottom: 20px;
        @include sp {
          margin-top: 40px;
        }
      }
      h3 {
        @include fz(20);
        line-height: 1.5em;
        border-bottom: 1px solid $grayBlack;
        position: relative;
        margin-top: 44px;
        margin-bottom: 20px;
        @include sp {
          margin-top: 30px;
        }
        &::before {
          content: "";
          display: block;
          max-width: 135px;
          width: 20%;
          height: 3px;
          background: $green;
          position: absolute;
          bottom: -1.5px;
          left: 0;
          z-index: 1;
        }
      }
      p {
        @include fz(16);
        line-height: 1.8em;
        margin-top: 16px;
      }
      ul li {
        list-style: disc;
        margin-left: 1.5em;
      }
      ol li {
        list-style: decimal;
        margin-left: 1.5em;
      }
      img {
        display: block;
        width: 85%!important;
        height: auto!important;
        // margin-top: 78px;
        margin: 78px auto 0;
      }
      .wp-caption {
        width: 100%!important;
      }
      .wp-caption .wp-caption-text {
        display: block;
        @include fz(16);
        text-align: center;
        color: $grayBlack;
        margin-top: 14px;
      }
    }
  }
  .c-btnBack {
    margin-top: 140px;
    @include sp {
      margin-top: 80px;
    }
  }
}
