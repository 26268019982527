@charset "UTF-8";

// frame size (height)
$frameSizePc: calc(100vh - 35px - 100px); // bottomの35px分とheaderの高さ100px分

// black
$black: #231815;

// white
$white: #fff;

// gray
$gray: #b3b9bb;
$grayTable: #e6e6e6;
$grayBlack: #4A575E;

// green
$green: #00c8ac;
$greenDark: #00b09c;
$greenDeep: #4a575e;
$greenLight: #e4f8f4;
$greenLight02: #D6F4ED;
$greenDeep02: #00b09c;

// blue
$blueOcean: #e4f8f4;
$blueLink: #2887cd;
$blueAnchor: #2887cd;

// yellow
$yellow: #f2e479;
$yellow02: #FFF2A7;
$yellowLight: #FFFDEF;
$yellowDark: #CEB63E;
$yellowTable: #ffe650;
$yellowTableLight: #fff3a7;

// red
$red: #d84d36;
