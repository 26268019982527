.topPage {
  .subArea {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include sp {
      display: block;
    }
  }
  .subArea_inner {
    max-width: 760px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include sp {
      display: block;
    }
    &_cmn {
      width: 46%;
      padding-top: calc(224 / 345 * 47%);
      background: url(../img/index/subArea_bg_01.jpg) center center /cover no-repeat;
      box-sizing: border-box;
      position: relative;
      @include sp {
        width: 100%;
        margin-top: 5%;
      }
      &_txt {
        widht: 100%;
        @include fz(38);
        font-weight: bold;
        text-align: center;
        text-shadow: 0 2px 4px rgba(0,0,0,.7);
        color: $white;
        position: relative;
        z-index: 1;
        // position: absolute;
        // top: 50%;
        // left: 0;
        // transform: translateY(-50%);
        @include sp {
          @include fz(24);
        }
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: 3px solid $green;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        &::before {
          content: "";
          display: block;
          width: calc(100% + 6px);
          height: calc(100% + 6px);
          background: $green;
          opacity: .2;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: .3s;
        }
        @include pc {
          &:hover {
            &::before {
              opacity: 1;
            }
            .subArea_inner_cmn_txt {
              text-shadow: none;
            }
          }
      	}

      }

      &.type--100 {
        width: 100%;
        background: url(../img/index/subArea_bg_02.jpg) center center /cover no-repeat;
        margin-top: 5%;
      }
    }
  }
}
