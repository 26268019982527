.newsList {
  a {
    background: $white;
    color: $black;
    display: block;
    transition: .3s;
  }
  &_ttl {
    @include fz(38);
    text-align: center;
    font-weight: bold;
    color: $grayBlack;
    @include sp {
      @include fz(24)
    }
  }

  .date {
    @include fz(16);
    // letter-spacing: -1px;
    font-weight: bold;
  }
  .tag {
    min-width: 110px;
    background: $grayBlack;
    @include fz(16);
    line-height: 1.5em;
    text-align: center;
    font-weight: bold;
    color: $white;
  }
  .txt {
    @include fz(16);
    line-height: 1.8em;
    // margin-top: 16px;
  }

  &_pickup {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 74px;
    @include sp {
      display: block;
    }
    .date {
      @include fz(17);
      letter-spacing: -1px;
      // font-weight: bold;
    }
    // .tag {
    //   min-width: 110px;
    //   background: $grayBlack;
    //   @include fz(16);
    //   line-height: 1.5em;
    //   text-align: center;
    //   font-weight: bold;
    //   color: $white;
    // }
    .txt {
      @include fz(17);
      line-height: 1.8em;
      margin-top: 16px;
      position: relative;
      z-index: 1;
    }
    &_cmn {
      max-width: 278px;
      width: 30%;
      position: relative;
      @include sp {
        width: 100%;
        max-width: none;
        & + .newsList_pickup_cmn {
          margin-top: 30px;
        }
      }
      a::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #FFF2A7;
        opacity: 0;
        transition: .3s;
        z-index: 1;
      }
      &_fig {
        width: 100%;
        padding-top: calc(136/ 278 * 100%);
        border: 1px solid $gray;
        position: relative;
        overflow: hidden;
        img {
          display: block;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &_sub {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;
        position: relative;
        z-index: 2;
      }

      @include pc {
        a:hover {
          &::before {
            opacity: .8;
          }
        }
    	}
    }
  }

  &_list {
    border-bottom: 1px solid $gray;
    margin-top: 80px;
    &, &_cmn + .newsList_list_cmn {
      border-top: 1px solid $gray;
    }
    &_cmn {
      &_inner {
        // width: calc(100% - (122 / 960 * 100%));
        max-width: 725px;
        width: 98%;
        display: flex;
        align-items: center;
        position: relative;
        left: calc(122 / 960 * 100%);
        padding: 24px;
        @include sp {
          flex-wrap: wrap;
          left: 0;
          padding: 20px 16px;
        }
      }

      .tag {
        margin: 0 26px;
      }
      .txt {
        @include sp {
          width: 100%;
          margin-top: 16px;
        }
      }

      @include pc {
        a:hover {
          // background: #FFF2A7;
          background: rgba(255, 242, 167, .8);
        }
    	}
    }
  }

  &_page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    a,
    span,
    .next,
    .prev {
      display: block;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      line-height: 25px;
      font-weight: bold;
      text-align: center;
      color: $white;
      & + * {
        margin-left: 12px;
      }
    }
    a,
    .next,
    .prev {
      transition: .2s;
      opacity: 1;
      @include pc {
        &:hover {
          opacity: .8;
        }
    	}
    }
    a {
      background: $grayBlack;
    }
    span {
      background: $green;
    }
    .next {
      background: url(../img/common/btn_icon_02.svg) center center /contain no-repeat;
    }
    .prev {
      background: url(../img/common/btn_icon_02.svg) center center /contain no-repeat;
      transform: rotate(180deg);
    }
  }
}
