.header {
	width: 100%;
	*{
		box-sizing: border-box;
	}
}

.header_cont {
	width: 100%;
	height: 100px;
	padding: 0 calc(40 / 1366 * 100vw);
	// padding: 0 40px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $green;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;

	@include sp {
		height: 8vh;
		padding: 0 4vw;
	}
}

.header_logo {
	width: 100%;

	@include pc {
		max-width: 324px;
		width: calc(304 / 1298 * 100%);
		padding: calc(10 / 1366 * 100vw) 0 0;
	}

	@include pcMin {
		font-size: 27vw;
	}

	@include sp {
		width: 50%;
		position: relative;
		z-index: 1;
	}
}

.header_nav {
	// max-width: 926px;
	width: calc(926 / 1298 * 100%);
	@include sp {
		// width: 50%;
		// display: none;
		width: 100%;
		height: 100vh;
		background: $green;
		position: fixed;
		top: 0;
		left: 0;
		opacity: 0;
		transition: .3s;
		pointer-events: none;
		&.active {
			opacity: 1;
			pointer-events: auto;
		}
	}
}

.headerNav_cont {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include sp {
		width: 100%;
		height: 70%;
		// justify-content: flex-end;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.headerNav {
	@include sp {
		width: 100%;
		height: 100%;
	}
}

.headerNav_list {
	display: inline-block;
	@include sp {
		margin-left: 0;
	}

	&--btn {
		// width: 162px;
		text-align: center;
		height: calc(52 / 1366 * 100vw);
		background: $white;
		// margin: 0 -8px 0 50px;
		@include sp {
			// width: 25%;
			min-width: 288px;
			height: auto;
			margin-left: 0;
			// display: flex;
			// align-items: center;
		}
	}
}

.headerNav_link {
	width: 100%;
	padding: calc(10 / 1366 * 100vw);
	display: block;
	// font-size: 18px;
	@include fz_vw(14.6);
	font-weight: bold;
	color: $white;
	position: relative;
	text-align: center;

	@include sp {
		padding: 0;
		@include sp_vw(26);
		letter-spacing: 1px;
	}
	@include spMini {
		@include fz(20);
	}

	&:after {
		content: "";
		width: 0;
		height: 3px;
		background-color: $white;
		position: absolute;
		bottom: -3px;
		left: 50%;
		transform: translateX(-50%);

		@include pc {
			transition: width 0.2s ease-in-out;
		}
	}
	@include pc {
		&:hover {
			&:after {
				@include pc {
					width: calc(100% - 20px);
				}
			}
		}
	}
}

.headerNav_btn {
	display: flex;
	width: 100%;
	height: 100%;
	background: $white;
	align-items: center;
	justify-content: center;

	// font-size: 18px;
	@include fz_vw(14.6);
	font-weight: bold;
	color: $green;

	box-sizing: border-box;
	border: calc(3 / 1366 * 100vw) solid $green;
	padding: 0 calc(19 / 1366 * 100vw);
	transition: .3s;
	@include pc {
		&:hover {
			background: $green;
			color: $white;
			border: calc(3 / 1366 * 100vw) solid $white;
		}
	}

	@include sp {
		@include fz(26);
		padding: 18px 16px;
	}
	@include spMini {
		@include fz(20);
	}
	// &--pc {
	// 	// height: 58px;
	// 	// height: calc(58 / 1366 * 100vw);
	//
	// 	@include sp {
	// 		display: none;
	// 	}
	// }
	//
	// &--sp {
	// 	@include pc {
	// 		display: none;
	// 	}
	//
	// 	@include sp {
	// 		width: 100%;
	// 	}
	// }
}

.header_btn {
	width: 36px;
	height: 28px;
	position: relative;
	z-index: 1;
	display: none;
	@include sp {
		display: block;
	}
	span,
	&::before,
	&::after {
		width: 100%;
		height: 4px;
		background: $white;
		border-radius: 2px;
		position: absolute;
		right: 0;
		transition: .3s;
	}
	&::before,
	&::after {
		content: "";
		transform: rotate(0);
		transform-origin: right;
	}
	span {
		top: 50%;
		transform: translateY(-50%);
		opacity: 1;
	}
	&::before {
		top: 0;
	}
	&::after {
		bottom: 0;
	}

	&.active {
		span {
			opacity: 0;
		}
		&::before {
			transform: rotate(-42deg);
		}
		&::after {
			transform: rotate(42deg);
		}
	}
}
