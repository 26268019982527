%btn {
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: bold;
	color: $green;
	background-color: $white;
	position: relative;
	z-index: 1;

	&:before {
		content: "";
		width: calc(100% + 12px);
		height: calc(100% + 12px);
		position: absolute;
		top: -6px;
		left: -6px;
		z-index: 2;
		opacity: 0;
		background-color: rgba($greenDeep, 0.3);
		transition: opacity 0.3s ease;
	}
	@include pc {
		&:hover {
			&:before {
				@include pc {
					opacity: 1;
				}
			}
		}
	}
}

%btnIco {
	display: block;
	background: url("../img/index/ico_mail.svg") no-repeat center;
	background-size: contain;
}

.btn {
	@extend %btn;
	padding: 20px;
	@include fz_vw(20);
	border: none;

	&:before {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	&--contact {
		@extend %btn;
		width: 100%;
		height: 100%;
		border: none;
		background: url("../img/index/mail_off.png") no-repeat center;
		background-size: contain;
		transition: background-image 0.3s;

		@include sp {
			height: 100%;
			@include fz_vw(14);
		}
		@include pc {
			&:hover {
				&:before {
					display: none;
				}
				background-image: url("../img/index/mail_on.png");
			}
		}
	}

	&--submit {
		@extend %btn;
		width: 100%;
		height: 100%;
		padding: 6%;
		@include fz_vw(20);
		border: none;
		box-shadow: none;
		-webkit-appearance: none;
		background: url("../img/index/confirm_off.png") no-repeat center;
		background-size: contain;

		@include pc {
			transition: background-image 0.3s ease;
		}

		&:hover {
			@include pc {
				background: url("../img/index/confirm_on.png") no-repeat center;
				background-size: contain;
			}

			&:before {
				display: none;
			}
		}

		&:hover:disabled, &:disabled {
			background: url("../img/index/confirm_not.png") no-repeat center;
			background-size: contain;
		}

		&:disabled {
			pointer-events: none;
		}
		&--edit {
			@extend .btn--submit;
			background: url("../img/index/edit_off.png") no-repeat center;
			background-size: contain;

			@include pc {
				&:hover {
					@include pc {
						background: url("../img/index/edit_on.png") no-repeat center;
						background-size: contain;
					}

					&:before {
						display: none;
					}
				}
			}
		}
	}

	&--send {
		@extend %btn;
		width: 100%;
		height: 100%;
		padding: 6%;
		@include fz_vw(20);
		border: none;
		box-shadow: none;
		-webkit-appearance: none;
		background: url("../img/index/send_off.png") no-repeat center;
		background-size: contain;

		@include pc {
			transition: background-image 0.3s ease;
		}

		@include sp {
		}

		@include pc {
			&:hover {
				@include pc {
					background: url("../img/index/send_on.png") no-repeat center;
					background-size: contain;
				}

				&:before {
					display: none;
				}
			}
		}
	}

	&--back {
		@extend .btn;
		background: none;
		font-size: 20px;
		background-color: $white;
		border: 3px solid $green;

		@include sp {
			font-size: 16px;
			border: 3px solid $green;
		}
	}
}

.btn_ico {
	@extend %btnIco;
	width: 22px;
	height: 17px;
	margin-right: 14px;

	&--contact {
		@extend %btnIco;
		width: 31px;
		height: 23px;
		margin-right: 20px;
	}
}


.c-btn {
	&More {
		width: 80%;
		max-width: 240px;
		margin: 40px auto 0;
		a {
			display: block;
			width: 100%;
			background: $white;
			@include fz(20);
			font-weight: bold;
			text-align: center;
			color: $green;
			// border: 4px solid $green;
			border: 3px solid $green;
			padding: 14px 0;
			position: relative;
			transition: .3s;
			&::before,
			&::after {
				content: "";
				display: block;
				width: 10%;
				height: 0;
				padding-top: 10%;
				position: absolute;
				top: 50%;
				right: 4%;
				transform: translateY(-50%);
				transition: .3s;
			}
			&::before {
				background: url(../img/common/btn_icon_02.svg) center center /contain no-repeat $green;
				border-radius: 50%;
				opacity: 1;
			}
			&::after {
				background: url(../img/common/btn_icon_white.svg) center center /contain no-repeat $white;
				border-radius: 50%;
				opacity: 0;
			}
			@include pc {
				&:hover {
					background: $green;
					color: $white;
					&::before {
						opacity: 0;
					}
					&::after {
						opacity: 1;
					}
				}
			}
		}
	}

	&Back {
		@extend .c-btnMore;
		a {
			&::before,
			&::after {
				transform: translateY(-50%) rotate(180deg);
				right: auto;
				left: 4%;
			}
		}
	}
}
