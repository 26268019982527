.informationPage {
  .info {
    // @include frame;
    // padding: 90px 0 0;

    @include pc {
      height: auto;
    }

    @include sp {
      padding: 0 0 0;
    }

    &--complete {
      height: 100% !important;
      padding: 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      @include pc {
        padding: 0 0 30px;
      }

      @include sp {
        padding: 0;
      }
    }

    &--option {
      @extend .info;
      // padding: 60px 0 215px;
      padding: 60px 0 152px;

      @include sp {
        padding: 30px 0 75px;
      }
      .text--info {
        @include sp {
          margin-top: 20px;
        }
      }
    }

    &--option2 {
      padding: 0 0 152px!important;

      @include sp {
        padding: 0 0 75px!important;
      }
    }
  }

  .info_note {
    padding: 80px 0 40px;
    text-align: center;
    line-height: 1.8;

    @include pcMin {
      padding: 3% 0 3%;
    }
    @include sp {
      padding: 30px 0;
    }

    &--anchor {
      padding: 20px 0 80px;
      text-align: center;
      line-height: 1.8;

      @include pcMin {
        padding: 3% 0;
      }
      @include sp {
        padding-bottom: 30px;
      }
    }
  }

  .info_anchor {
    color: $blueAnchor;

    &:hover {
      @include pc {
        text-decoration: underline;
      }
    }
  }

  .info_table {
    width: 100%;
    position: relative;
    z-index: 0;

    @include sp {
      overflow-x: scroll;
    }

    &:first-child {
      margin-top: 30px;

      @include sp {
        margin-top: 40px;
      }
    }

    & + & {
      margin-top: 90px;

      @include pcMin {
        margin-top: 12%;
      }

      @include sp {
        margin-top: 75px;
      }
    }

    &--bg {
      width: 75%;
      margin: 0 auto;

      @include sp {
        width: 100%;
      }
    }
  }

  .infoTable {
    width: 100%;
    font-size: 16px;
    table-layout: fixed;

    @include pcMin {
      font-size: 1.34vw;
    }

    @include sp {
      width: 600px;
      margin: 0 auto;
    }

    th,
    td {
      padding: 12px 0;
      text-align: center;
      border: 1px solid $greenDeep;
      word-break: break-all;
      vertical-align: middle;

      @include pcMin {
        padding: 2% 0;
      }

      @include sp {
        font-size: 12px;
      }
    }

    th {
      letter-spacing: 0.1em;
    }
  }

  .infoTable_small {
    padding-left: 3px;
    display: inline-block;

    &--small {
      line-height: 1.4;
      font-size: 14px;

      @include pcMin {
        font-size: 1vw;
      }
    }
  }

  .infoTable_green {
    vertical-align: middle;
    font-weight: bold;
    color: $white;
    background-color: $greenDark;
    letter-spacing: 0.1em;

    &--deep {
      color: $white;
      background-color: $greenDeep;
    }
  }

  .infoTable_white {
    background-color: $white;
  }

  .infoTable_gray {
    background-color: $grayTable;
  }

  .infoTable_yellow {
    font-weight: normal;
    background-color: $yellowTable;

    &--light {
      font-weight: normal;
      background-color: $yellowTableLight;
    }
  }

  .infoTable_size--w60 {
    width: 60%;
  }

  .infoTable_size--w40 {
    width: 40%;
  }

  .infoTable_size--w33 {
    width: 33%;
  }

  .infoTable_size--w20 {
    width: 20%;
  }

  .infoTable_size--w15 {
    width: 15%;
  }

  .pdfLink {
    display: block;
    width: 57%;
    background: $white;
    @include fz_vw(15);
    line-height: 1.5em;
    font-weight: bold;
    text-align: center;
    color: $greenDark;
    border-radius: 20px;
    border: 2px solid $greenDark;
    margin: 12px auto 0;
    transition: .2s;
    @include sp {
      width: 90%;
      @include fz(12);
    }
    @include pc {
      &:hover {
        background: $greenDark;
        color: $white;
      }
  	}
  }

  // .warranty {
  //   padding-bottom: 92px;
  //   @include sp {
  //     padding-bottom: 32px;
  //     width: 90%;
  //     margin: 0 auto;
  //   }
  //   &_cont {
  //     display: flex;
  //     justify-content: space-between;
  //     border: 4px solid $green;
  //     border-radius: 10px;
  //     padding: 56px 76px 60px;
  //     margin-top: 42px;
  //     @include sp {
  //       display: block;
  //       padding: 30px 40px 20px;
  //     }
  //     &_cmn {
  //       width: 50%;
  //       @include sp {
  //         width: 100%;
  //         & + .warranty_cont_cmn {
  //           margin-top: 30px;
  //         }
  //       }
  //       figcaption {
  //         @include fz(16);
  //         line-height: 1.8em;
  //         text-align: center;
  //         margin-top: 38px;
  //         @include sp {
  //           @include fz(14);
  //         }
  //       }
  //     }
  //   }
  //   &_contact {
  //     background: $greenLight;
  //     border-radius: 10px;
  //     padding: 30px 56px 58px;
  //     margin-top: 52px;
  //     @include sp {
  //       padding: 20px 20px 32px;
  //       margin-top: 26px;
  //     }
  //     &_txt {
  //       @include fz(16);
  //       line-height: 1.8em;
  //       margin-top: 10px;
  //       // & + .warranty_contact_txt {
  //       // }
  //       @include sp {
  //         @include fz(14);
  //         margin-top: 6px;
  //       }
  //     }
  //     &_tel {
  //       position: relative;
  //       display: flex;
  //       align-items: center;
  //       margin-top: 32px;
  //       @include sp {
  //         margin-top: 0;
  //         margin-bottom: 20px;
  //       }
  //       @include pc {
  //   			pointer-events: none;
  //   			text-decoration: none;
  //   		}
  //       &::before {
  //         content: "";
  //         display: inline-block;
  //         width: 56px;
  //         height: 36px;
  //         background: url(../img/common/tel_icon.svg) center center /contain no-repeat;
  //         margin-right: 12px;
  //       }
  //       a {
  //         @include fz(38);
  //         font-weight: bold;
  //         color: $black;
  //         @include sp {
  //           @include fz(24);
  //         }
  //       }
  //     }
  //     &_mail {
  //       @include fz(24);
  //       font-weight: bold;
  //       color: $green;
  //       margin-top: 32px;
  //       @include sp {
  //         @include fz(16);
  //         margin-top: 22px;
  //       }
  //       a {
  //         display: inline-block;
  //         color: $black;
  //       }
  //     }
  //   }
  // }

  .warranty {
    padding-bottom: 92px;
    @include sp {
      padding-bottom: 32px;
      width: 90%;
      margin: 0 auto;
    }
    .c-ttl_icon {
      width: 70px;
    }
    &_ttl {
      @include fz(24);
      font-weight: bold;
      text-align: center;
      color: $greenDark;
      @include sp {
        @include fz(20);
      }
      &.type02 {
        span {
          position: relative;
          &::before,
          &::after {
            content:"";
            display: block;
            width: 2px;
            height: 110%;
            background: $greenDark;
            position: absolute;
            top: 4px;
            transform-origin: bottom;
          }
          &::before {
            left: -14px;
            transform: rotate(-35deg);
          }
          &::after {
            right: -14px;
            transform: rotate(35deg);
          }
        }
      }
    }
    &_cmn {
      margin-top: 72px;
      &_list {
        border: 4px solid $green;
        padding-bottom: 54px;
        margin-top: 52px;
        position: relative;
        h4 {
          @include fz(24);
          line-height: 1.5em;
          font-weight: bold;
          text-align: center;
          color: $white;
          padding: 6px 30px;
          background: $green;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          @include sp {
            width: 90%;
            @include fz(18);
          }
        }
        figure {
          max-width: 576px;
          width: 90%;
          margin: 56px auto 0;
          figcaption {
            line-height: 1.8em;
            margin-top: 48px;
            &.type02 {
              text-align: center;
            }
          }
        }
      }
    }
    &_contact {
      background: $greenLight;
      border-radius: 10px;
      padding: 30px 56px 58px;
      margin-top: 52px;
      @include sp {
        padding: 20px 20px 32px;
        margin-top: 26px;
      }
      &_txt {
        @include fz(16);
        line-height: 1.8em;
        margin-top: 10px;
        // & + .warranty_contact_txt {
        // }
        @include sp {
          @include fz(14);
          margin-top: 6px;
        }
      }
      &_tel {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 32px;
        @include sp {
          margin-top: 0;
          margin-bottom: 20px;
        }
        @include pc {
    			pointer-events: none;
    			text-decoration: none;
    		}
        &::before {
          content: "";
          display: inline-block;
          width: 56px;
          height: 36px;
          background: url(../img/common/tel_icon.svg) center center /contain no-repeat;
          margin-right: 12px;
        }
        a {
          @include fz(38);
          font-weight: bold;
          color: $black;
          @include sp {
            @include fz(24);
          }
        }
      }
      &_mail {
        @include fz(24);
        font-weight: bold;
        color: $green;
        margin-top: 32px;
        @include sp {
          @include fz(16);
          margin-top: 22px;
        }
        a {
          display: inline-block;
          color: $black;
        }
      }
    }
  }

  .attention {
    width: 100%;
    text-indent: -1em;
    line-height: 1.8em;
    background: $grayTable;
    padding: 4px 20px;
    padding-left: calc(1em + 20px);
    margin-top: 24px;
    @include pcMin {
  		font-size: 1.25vw;
  	}
    @include sp {
      width: 90%;
      margin: 20px auto 0;
    }
  }
}
