.underlayer {
  padding-top: 100px;
  @include sp{
    padding-top: 8vh;
  }
  * {
    box-sizing: border-box;
  }
  .cu-sec {
    width: 100%;
    &:nth-of-type(2){
      padding-top: 44px;
    }

    &_inner {
      max-width: 960px;
      width: 90%;
      margin: 0 auto;
      padding-bottom: 58px;
      &.type-mini {
        max-width: 704px;
      }
    }
  }

}
