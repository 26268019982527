.storyPage {
  &_inner {
    background: url(../img/story/bg.jpg) center center /contain repeat;
    @include sp {
      background: $white;
    }
  }
  .storySec {
    width: 90%;
    max-width: 960px;
    background: $white;
    padding: 77px 0 94px;
    margin: 0 auto;
    @include sp {
      padding: 30px 0 40px;
    }
    &_inner {
      width: 90%;
      max-width: 708px;
      margin: 0 auto;
      @include sp {
        width: 100%;
      }
    }
  }
  .txt {
    @include fz(16);
    line-height: 2em;
    margin-top: 58px;
    @include sp {
      margin-top: 48px;
    }
    strong {
      display: inline-block;
      @include fz(20);
      color: $greenDark;
      margin-bottom: 28px;
    }
    a {
      color: $greenDark;
      text-decoration: underline;
    }
  }
  .subTtl {
    @include fz(38);
    line-height: 1.5em;
    text-align: center;
    color: $grayBlack;
    margin-top: 106px;
    span {
      @include fz(25);
    }

    &::before {
      content: "";
      display: block;
      width: 48px;
      height: 56px;
      background: url(../img/story/ttl_icon_01.svg) center center /contain no-repeat;
      margin: 0 auto 20px;
    }
  }

  .voice {
    &_ttl {
      margin-top: 94px;
      position: relative;

      &::before {
        content: "";
        display: block;
        max-width: 418px;
        width: 90%;
        height: 3px;
        background: $green;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &_inner {
        width: 324px;
        background: $white;
        @include fz(25);
        text-align: center;
        color: $grayBlack;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 0 22px;
        position: relative;
        z-index: 1;
        .mini {
          @include fz(20);
          margin-left: 10px;
        }
      }
    }


    &_list {
    }
    &_cmn {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 46px;
      &:nth-child(2n){
        margin-top: 32px;
      }
      &_participant {
        width: 70px;
        position: relative;
        @include sp {
          width: 35px;
        }
        img {
          width: 100%;
        }
        .name {
          @include fz(13);
          line-height: 1.8em;
          text-align: center;
          white-space: nowrap;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          @include sp {
            @include fz(10);
          }
        }
      }
      &_txtArea {
        width: calc(100% - 106px);
        background: #D6F4ED;
        padding: 24px 32px;
        border-radius: 32px;
        position: relative;
        opacity: 0;
        transform: scale(.8);
        left: -10px;
        transition: .3s;
        @include sp {
          width: calc(100% - 60px);
          padding: 24px 20px;
        }
        &::before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent #D6F4ED transparent transparent;
          border-width: 10px 32px 10px 0;
          position: absolute;
          top: 28px;
          left: -30px;
          @include sp {
            border-width: 6px 20px 6px 0;
            top: 18px;
            left: -18px;
          }
        }
      }
      &_catch {
        display: inline-block;
        @include fz(20);
        line-height: 1.5em;
        font-weight: bold;
        border-bottom: 6px solid #ACEADC;
        position: relative;
        padding: 0 10px 4px;
        margin: 0 20px;
        &::before,
        &::after {
          @include fz(40);
          color: $greenDark;
          position: absolute;
          top:0;
        }
        &::before {
          content: "“";
          left: -20px;
          transform: translateY(-20%);
        }
        &::after {
          content: "”";
          right: -20px;
          transform: translateY(-10%);
        }
      }
      &_txt {
        @include fz(16);
        line-height: 2em;
        margin-top: 14px;
      }

      &:nth-child(4n-1),
      &:nth-child(4n) {
        flex-direction: row-reverse;
        .voice_cmn_txtArea {
          background: #FFF6C2;
          left: 10px;
          &::before {
            border-color: transparent transparent transparent #FFF6C2;
            border-width: 10px 0 10px 32px;
            left: auto;
            right: -30px;
            @include sp {
              border-width: 6px 0 6px 20px;
              right: -18px;
            }
          }
        }
        .voice_cmn_catch {
          border-bottom: 6px solid #FFED84;
          &::before,
          &::after {
            @include fz(40);
            color: $yellowDark;
          }
        }
      }

      &.action {
        .voice_cmn_txtArea {
          opacity: 1;
          transform: scale(1);
          left: 0;
        }
      }
    }
  }
}
