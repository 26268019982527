.videoSingle {
  padding-bottom: 70px;

  .videoSingle_cont {
    &_inner {
      @include fz(16);
      line-height: 1.8em;
      h2 {
        @include fz(30);
        line-height: 1.5em;
        color: $green;
        margin-top: 90px;
        margin-bottom: 20px;
        @include sp {
          margin-top: 40px;
        }
      }
      h3 {
        @include fz(20);
        line-height: 1.5em;
        border-bottom: 1px solid $grayBlack;
        position: relative;
        margin-top: 44px;
        margin-bottom: 20px;
        @include sp {
          margin-top: 30px;
        }
        &::before {
          content: "";
          display: block;
          max-width: 135px;
          width: 20%;
          height: 3px;
          background: $green;
          position: absolute;
          bottom: -1.5px;
          left: 0;
          z-index: 1;
        }
      }
      p {
        @include fz(16);
        line-height: 1.8em;
        margin-top: 16px;
      }
      ul li {
        list-style: disc;
        margin-left: 1.5em;
      }
      ol li {
        list-style: decimal;
        margin-left: 1.5em;
      }
      img {
        display: block;
        width: 100% !important;
        height: auto !important;
        margin: 78px auto 0;
      }
      .wp-caption {
        width: 100% !important;
      }
      .wp-caption .wp-caption-text {
        display: block;
        @include fz(16);
        text-align: center;
        color: $grayBlack;
        margin-top: 14px;
      }
    }
  }
  .c-btnBack {
    margin-top: 140px;
    @include sp {
      margin-top: 80px;
    }
  }
  .youtube {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
