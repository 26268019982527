// %title {
// 	@include fz_vw(60);
// 	font-weight: bold;

// 	@include sp {
// 		font-size: 24px;
// 	}
// }

.title {
	font-size: 38px;
	line-height: 1;
	color: $greenDeep;
	text-align: center;

	@include pcMin {
		font-size: 3.15vw;
	}

	@include sp {
		font-size: 24px;
	}

	&--about {
		// @extend %title;
		margin-bottom: 2vw;
		line-height: 1.73;
		color: $greenDark;
		font-size: 38px;
		text-align: center;
		letter-spacing: 0.05em;

		@include pcMin {
			font-size: 3.15vw;
			line-height: 1.5;
		}

		@include sp {
			margin-bottom: 5%;
			font-size: 24px;
		}
	}

	&--single {
		font-size: 50px;
		line-height: 1.74;
		letter-spacing: 0.1em;

		@include pcMin {
			font-size: 4.18vw;
		}

		@include sp {
			font-size: 28px;
		}
	}
}

.titleBox {
	margin-bottom: 30px;

	@include pcMin {
		margin: 0 0 2%;
	}

	@include sp {
		margin: 30px 0;
	}
}

.title_ico {
	width: 100%;
	max-width: 90px;
	margin: 0 auto 2.4vw;

	@include pcMin {
		max-width: none;
		width: 7.5vw;
		margin: 0 auto 3%;
	}

	@include sp {
		width: 13vw;
		margin: 0 auto 7%;
	}

	&--contact {
		width: 3.3vw;
		margin: 0 auto 2vw;

		@include sp {
			width: 11%;
			margin: 0 auto 7%;
		}
	}
}

.titleSub {
	margin: 40px auto 30px;
	text-align: center;

	@include pcMin {
		margin: 1% 0;
	}

	@include sp {
		margin: 35px auto 0;
		padding: 0;
	}

	&--lead {
		margin: 0 auto 30px;
	}
}

.titleSub_text {
	width: 200px;
	padding: 0 0 16px;
	display: inline-block;
	font-size: 24px;
	color: $greenDark;
	text-align: center;
	background: url("../img/index/title_border.png") no-repeat right bottom;
	background-size: contain;

	@include pcMin {
		width: auto;
		padding: 0 3% 1.5%;
		font-size: 2vw;
	}

	@include sp {
		font-size: 20px;
	}
}

.c-ttl {
	text-align: center;
	margin-top: 0;
	&_icon {
		width: 90px;
		margin: 0 auto;
		@include sp{
			width: 78px;
		}
	}
	&_txt {
		@include fz_vw(38);
		color: $grayBlack;
		margin-top: 26px;
		@include sp {
			@include fz(26);
			margin-top: 22px;
		}
	}
}
