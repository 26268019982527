.footer {
	@include frame;
	padding: 0 4%;
	box-sizing: border-box;
	position: relative;

	@include sp {
		height: auto;
	}

	&:before {
		content: "";
		width: 100%;
		height: 50%;
		background-color: $greenLight;
		position: absolute;
		top: -25%;
		left: 0;
		z-index: -1;
	}
}

.footer_cont {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;

	@include sp {
		padding: 75px 0 20px;
	}
}

.footer_logo {
	width: 45%;
	margin: 0 auto 60px;

	@include pc {
		max-width: 656px;
	}

	@include sp {
		width: 70%;
		margin: 0 auto 8%;
	}
}

.footer_lead {
	font-size: 24px;
	font-weight: bold;
	line-height: 1.72;
	color: $white;

	@include pcMin {
		font-size: 2vw;
	}

	@include sp {
		padding-bottom: 1%;
		font-size: 16px;
	}
}

.footer_text {
	font-size: 20px;
	line-height: 2.2;
	letter-spacing: 0.075em;
	color: $white;

	@include pcMin {
		font-size: 1.67vw;
		line-height: 1.5;
	}

	@include sp {
		padding-bottom: 1%;
		// font-size: 14px;
	}

	a {
		color: $white;
		text-decoration: underline;
		@include pc {
			pointer-events: none;
			text-decoration: none;
		}
	}

	&--note {
		font-size: 16px;
		color: $white;
		line-height: 2.2;
		letter-spacing: 0.075em;

		@include pcMin {
			font-size: 1.34vw;
		}

		@include sp {
			font-size: 14px;
		}
	}
}

.footer_btn {
	width: 320px;
	height: 76px;
	margin: 8% 0 4vw;

	@include pcMin {
		width: 22%;
		height: 6.4vw;
		margin: 3% 0;
	}

	@include sp {
		width: 60%;
		margin: 10% 0;
	}
}

.footer_copy {
	margin: 5vh auto 0;
	color: $white;
	position: absolute;
	bottom: 1vh;
}

.footer_privacy {
	margin: 2vh auto 10vh;
	text-decoration: underline;
	cursor: pointer;
	color: $white;
}

.footerText {
	width: 70%;
	margin: 0 auto;

	@include pc {
		max-width: 847px;
	}

	@include pcMin {
		max-width: none;
	}

	@include sp {
		width: 100%;
		padding: 0 5%;
		box-sizing: border-box;
	}
}

.footer_links {
	width: 57%;
	margin: 3% auto 1%;
	position: relative;
	@include sp {
		width: 100%;
	}
	&_btn {
		width: 34%;
		margin: 0 auto;
		@include sp {
			width: 60%;
		}
		a {
			display: block;
			width: 100%;
			height: 100%;
			background: url(../img/common/contact_btn_hover.svg) center center /contain no-repeat;
			img {
				opacity: 1;
				transition: .3s;
			}
			@include pc {
				&:hover img {
					opacity: 0;
				}
			}
		}
	}

	&_sns {
		width: 7%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		@include sp {
			width: 10%;
			margin: 20px auto 0;
			position: relative;
			top:0;
			transform: none;
		}
		&_cmn {
			width: 100%;
			a {
				display: block;
			}
		}
	}
}
