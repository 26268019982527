%ballon {
	width: 100%;
	display: flex;
	align-items: center;
}

%ballonText {
	padding: 10px 20px;
	position: relative;
	border-radius: 50px;
	opacity: 0;
	transform: scale(0.8);
	transition: opacity 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95),
		transform 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95);

	&.is-view {
		opacity: 1;
		transform: scale(1);
	}
}

%ballonEdge {
	content: "";
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.ballon {
	@extend %ballon;

	&--reverse {
		@extend %ballon;
		flex-direction: row-reverse;
	}
}

.ballon_ico {
	width: 4.2vw;

	@include sp {
		width: 22%;
	}
}

.ballon_text {
	&--left {
		&--green {
			@extend %ballonText;
			margin-left: 20px;
			color: $white;
			background-color: $green;

			@include sp {
				width: 70%;
				// margin-left: auto;
			}

			&:before {
				@extend %ballonEdge;
				border-width: 6px 20px 6px 0;
				border-color: transparent $green transparent transparent;
				left: -19px;
			}
		}

		&--white {
			@extend %ballonText;
			margin-left: 20px;
			color: $greenDark;
			background-color: $white;

			@include sp {
				width: 70%;
				// margin-left: auto;
			}

			&:before {
				@extend %ballonEdge;
				border-width: 6px 20px 6px 0;
				border-color: transparent $white transparent transparent;
				left: -19px;
			}
		}
	}

	&--right {
		&--white {
			@extend %ballonText;
			margin-right: 20px;
			color: $greenDark;
			background-color: $white;

			@include sp {
				width: 70%;
			}

			&:before {
				@extend %ballonEdge;
				border-width: 6px 0 6px 20px;
				border-color: transparent transparent transparent $white;
				right: -19px;
			}
		}
	}
}
