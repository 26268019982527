@charset "UTF-8";


/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

li {
  list-style: none;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select,
img {
  vertical-align: bottom;
}

button {
  background-color: transparent;
  cursor: pointer;
  appearance: none;
}

button, input[type="text"] {
  border: none;
  outline: none;
  padding: 0;
}

input[type="text"] {
  border-radius: 0;
  background: none;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  background: #ff0000;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + label {
  background: #ff0000;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  outline: none;
  background: rgba(0, 0, 0, 0);
}

input[type=submit], select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: rgba(0, 0, 0, 0);
}

input[type=submit] {
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  width: 100%;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
}

@media screen and (min-width: 769px) {
  body {
    overflow-x: hidden;
  }
}

body.is-fixed {
  height: 100%;
  overflow: hidden;
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: "メイリオ", Meiryo, sans-serif;
  }
}

main {
  position: relative;
}

input,
select,
button {
  cursor: pointer;
}

input:focus,
select:focus,
button:focus {
  outline: none;
}

img {
  width: 100%;
  display: block;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

@media screen and (max-width: 768px) {
  #contact {
    margin-top: 8vh;
  }
}

.section, .section--about, .section--subArea, .section--promiseMv, .section--simple, .section--promiseMv02, .section--promise, .section--promisePic, .section--promisePic02, .section--option, .section--case, .section--news, .section--footer {
  width: 100%;
  padding-top: 100px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .section, .section--about, .section--subArea, .section--promiseMv, .section--simple, .section--promiseMv02, .section--promise, .section--promisePic, .section--promisePic02, .section--option, .section--case, .section--news, .section--footer {
    height: auto !important;
    padding-top: 0;
  }
}

.section--about {
  padding: 150px 0;
  background: url("../img/index/bg_about.jpg") repeat-y 0 0;
  background-size: 100%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .section--about {
    background: #e4f8f4;
    padding-bottom: 0;
  }
}

.section--about::before {
  content: "";
  width: 75%;
  height: calc(100% + 100px);
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .section--about::before {
    display: none;
    height: 100%;
  }
}

.section--subArea {
  padding: 150px 0;
  background: url("../img/index/bg_about.jpg") repeat-y 0 0;
  background-size: 100%;
}

@media screen and (max-width: 768px) {
  .section--subArea {
    padding: 40px 0;
  }
}

.section--promiseMv {
  position: relative;
}

.section--promiseMv:before {
  top: 0;
  background: url("../img/index/bg_about.jpg") repeat-y 0 0;
  background-size: 100%;
}

.section--promiseMv:after, .section--promiseMv:before {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  z-index: -1;
}

.section--promiseMv:after {
  bottom: 0;
  background-color: #00c8ac;
}

.section--simple {
  background-color: #00c8ac;
}

.section--promiseMv02 {
  position: relative;
}

.section--promiseMv02:before {
  top: 0;
  background-color: #00c8ac;
}

.section--promiseMv02:after, .section--promiseMv02:before {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  z-index: -1;
}

.section--promiseMv02:after {
  bottom: 0;
  background-color: #e4f8f4;
}

.section--promise {
  padding: 150px 0;
  background-color: #e4f8f4;
  position: relative;
}

@media screen and (max-width: 768px) {
  .section--promise {
    padding: 40px 0;
  }
}

.section--promise:before {
  top: 0;
}

.section--promise:after, .section--promise:before {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  background-color: #e4f8f4;
  z-index: -1;
}

.section--promise:after {
  bottom: 0;
}

.section--promisePic, .section--promisePic02 {
  position: relative;
}

.section--promisePic:before, .section--promisePic02:before {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e4f8f4;
  z-index: -1;
}

.section--option {
  background-color: #e4f8f4;
  position: relative;
}

.section--option:after {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #e4f8f4;
  z-index: -1;
}

.section--case {
  position: relative;
  padding: 150px 0;
}

@media screen and (max-width: 768px) {
  .section--case {
    padding: 60px 0;
  }
}

.section--news {
  position: relative;
  padding: 150px 0 100px;
  background-color: #e4f8f4;
}

@media screen and (max-width: 768px) {
  .section--news {
    padding: 60px 0;
  }
}

.section--news:after {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #e4f8f4;
  z-index: -1;
}

.section--footer {
  background: url("../img/index/bg_footer.jpg") no-repeat top center;
  background-size: cover;
  overflow: hidden;
}

.section--complete {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .section--complete {
    height: 100vh !important;
    margin: 0 !important;
  }
}

.pager {
  position: fixed;
  top: calc(50% - -2.3vw);
  left: 6%;
  z-index: 9991;
  transform: translateY(-50%);
  padding: 0;
}

@media screen and (max-width: 768px) {
  .pager {
    display: none;
  }
}

.pager li {
  display: block;
  margin: 20px;
}

.pager li:first-child.is-current a, .pager li:nth-of-type(4).is-current a {
  border-color: #fff;
}

.pager li a {
  display: block;
  width: 10px;
  height: 10px;
  background: #fff;
  border: 2px solid #00c8ac;
  border-radius: 50%;
}

.pager li.is-current a {
  background: #00c8ac;
}

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: -9998;
  opacity: 0;
}

.overlay.is-view {
  opacity: 1;
  z-index: 9998;
}

@media screen and (min-width: 769px) {
  .pc {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .sp {
    display: block;
  }
}

.wrap {
  width: 75%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .wrap {
    width: 100%;
  }
}

.wrap--info {
  padding: 0 0 125px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .wrap--info {
    padding: 0 0 90px;
  }
}

.wrap--info, .wrap--info:after {
  width: 100%;
  background-color: #e4f8f4;
}

.wrap--info:after {
  content: "";
  height: 35px;
  position: absolute;
  top: 100%;
  left: 0;
}

@media screen and (min-width: 1200px) {
  .wrap--info:after {
    height: 5%;
  }
}

@media screen and (max-width: 768px) {
  .wrap--info:after {
    display: none;
  }
}

.header {
  width: 100%;
}

.header * {
  box-sizing: border-box;
}

.header_cont {
  width: 100%;
  height: 100px;
  padding: 0 calc(40 / 1366 * 100vw);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00c8ac;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

@media screen and (max-width: 768px) {
  .header_cont {
    height: 8vh;
    padding: 0 4vw;
  }
}

.header_logo {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .header_logo {
    max-width: 324px;
    width: calc(304 / 1298 * 100%);
    padding: calc(10 / 1366 * 100vw) 0 0;
  }
}

@media screen and (min-width: 1200px) {
  .header_logo {
    font-size: 27vw;
  }
}

@media screen and (max-width: 768px) {
  .header_logo {
    width: 50%;
    position: relative;
    z-index: 1;
  }
}

.header_nav {
  width: calc(926 / 1298 * 100%);
}

@media screen and (max-width: 768px) {
  .header_nav {
    width: 100%;
    height: 100vh;
    background: #00c8ac;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .3s;
    pointer-events: none;
  }
  .header_nav.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.headerNav_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .headerNav_cont {
    width: 100%;
    height: 70%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 768px) {
  .headerNav {
    width: 100%;
    height: 100%;
  }
}

.headerNav_list {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .headerNav_list {
    margin-left: 0;
  }
}

.headerNav_list--btn {
  text-align: center;
  height: calc(52 / 1366 * 100vw);
  background: #fff;
}

@media screen and (max-width: 768px) {
  .headerNav_list--btn {
    min-width: 288px;
    height: auto;
    margin-left: 0;
  }
}

.headerNav_link {
  width: 100%;
  padding: calc(10 / 1366 * 100vw);
  display: block;
  font-size: 14.6px;
  font-size: 0.99561vw;
  font-weight: bold;
  color: #fff;
  position: relative;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .headerNav_link {
    padding: 0;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .headerNav_link {
    font-size: 26px;
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 500px) {
  .headerNav_link {
    font-size: 20px;
    font-size: 2;
  }
}

.headerNav_link:after {
  content: "";
  width: 0;
  height: 3px;
  background-color: #fff;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 769px) {
  .headerNav_link:after {
    transition: width 0.2s ease-in-out;
  }
}

@media screen and (min-width: 769px) and (min-width: 769px) {
  .headerNav_link:hover:after {
    width: calc(100% - 20px);
  }
}

.headerNav_btn {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
  align-items: center;
  justify-content: center;
  font-size: 14.6px;
  font-size: 0.99561vw;
  font-weight: bold;
  color: #00c8ac;
  box-sizing: border-box;
  border: calc(3 / 1366 * 100vw) solid #00c8ac;
  padding: 0 calc(19 / 1366 * 100vw);
  transition: .3s;
}

@media screen and (min-width: 769px) {
  .headerNav_btn:hover {
    background: #00c8ac;
    color: #fff;
    border: calc(3 / 1366 * 100vw) solid #fff;
  }
}

@media screen and (max-width: 768px) {
  .headerNav_btn {
    font-size: 26px;
    font-size: 2.6;
    padding: 18px 16px;
  }
}

@media screen and (max-width: 500px) {
  .headerNav_btn {
    font-size: 20px;
    font-size: 2;
  }
}

.header_btn {
  width: 36px;
  height: 28px;
  position: relative;
  z-index: 1;
  display: none;
}

@media screen and (max-width: 768px) {
  .header_btn {
    display: block;
  }
}

.header_btn span, .header_btn::before, .header_btn::after {
  width: 100%;
  height: 4px;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  right: 0;
  transition: .3s;
}

.header_btn::before, .header_btn::after {
  content: "";
  transform: rotate(0);
  transform-origin: right;
}

.header_btn span {
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
}

.header_btn::before {
  top: 0;
}

.header_btn::after {
  bottom: 0;
}

.header_btn.active span {
  opacity: 0;
}

.header_btn.active::before {
  transform: rotate(-42deg);
}

.header_btn.active::after {
  transform: rotate(42deg);
}

.footer {
  padding: 0 4%;
  box-sizing: border-box;
  position: relative;
}

@media screen and (min-width: 769px) {
  .footer {
    width: 100vw;
    width: 100vw;
    height: calc(100vh - 35px - 100px);
    padding-right: 35px;
    padding-left: 35px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    width: 100%;
    height: calc(100% - 4vw);
  }
}

@media screen and (max-width: 768px) {
  .footer {
    height: auto;
  }
}

.footer:before {
  content: "";
  width: 100%;
  height: 50%;
  background-color: #e4f8f4;
  position: absolute;
  top: -25%;
  left: 0;
  z-index: -1;
}

.footer_cont {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .footer_cont {
    padding: 75px 0 20px;
  }
}

.footer_logo {
  width: 45%;
  margin: 0 auto 60px;
}

@media screen and (min-width: 769px) {
  .footer_logo {
    max-width: 656px;
  }
}

@media screen and (max-width: 768px) {
  .footer_logo {
    width: 70%;
    margin: 0 auto 8%;
  }
}

.footer_lead {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.72;
  color: #fff;
}

@media screen and (min-width: 1200px) {
  .footer_lead {
    font-size: 2vw;
  }
}

@media screen and (max-width: 768px) {
  .footer_lead {
    padding-bottom: 1%;
    font-size: 16px;
  }
}

.footer_text {
  font-size: 20px;
  line-height: 2.2;
  letter-spacing: 0.075em;
  color: #fff;
}

@media screen and (min-width: 1200px) {
  .footer_text {
    font-size: 1.67vw;
    line-height: 1.5;
  }
}

@media screen and (max-width: 768px) {
  .footer_text {
    padding-bottom: 1%;
  }
}

.footer_text a {
  color: #fff;
  text-decoration: underline;
}

@media screen and (min-width: 769px) {
  .footer_text a {
    pointer-events: none;
    text-decoration: none;
  }
}

.footer_text--note {
  font-size: 16px;
  color: #fff;
  line-height: 2.2;
  letter-spacing: 0.075em;
}

@media screen and (min-width: 1200px) {
  .footer_text--note {
    font-size: 1.34vw;
  }
}

@media screen and (max-width: 768px) {
  .footer_text--note {
    font-size: 14px;
  }
}

.footer_btn {
  width: 320px;
  height: 76px;
  margin: 8% 0 4vw;
}

@media screen and (min-width: 1200px) {
  .footer_btn {
    width: 22%;
    height: 6.4vw;
    margin: 3% 0;
  }
}

@media screen and (max-width: 768px) {
  .footer_btn {
    width: 60%;
    margin: 10% 0;
  }
}

.footer_copy {
  margin: 5vh auto 0;
  color: #fff;
  position: absolute;
  bottom: 1vh;
}

.footer_privacy {
  margin: 2vh auto 10vh;
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
}

.footerText {
  width: 70%;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  .footerText {
    max-width: 847px;
  }
}

@media screen and (min-width: 1200px) {
  .footerText {
    max-width: none;
  }
}

@media screen and (max-width: 768px) {
  .footerText {
    width: 100%;
    padding: 0 5%;
    box-sizing: border-box;
  }
}

.footer_links {
  width: 57%;
  margin: 3% auto 1%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .footer_links {
    width: 100%;
  }
}

.footer_links_btn {
  width: 34%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .footer_links_btn {
    width: 60%;
  }
}

.footer_links_btn a {
  display: block;
  width: 100%;
  height: 100%;
  background: url(../img/common/contact_btn_hover.svg) center center/contain no-repeat;
}

.footer_links_btn a img {
  opacity: 1;
  transition: .3s;
}

@media screen and (min-width: 769px) {
  .footer_links_btn a:hover img {
    opacity: 0;
  }
}

.footer_links_sns {
  width: 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .footer_links_sns {
    width: 10%;
    margin: 20px auto 0;
    position: relative;
    top: 0;
    transform: none;
  }
}

.footer_links_sns_cmn {
  width: 100%;
}

.footer_links_sns_cmn a {
  display: block;
}

.btn, .btn--back, .btn--contact, .btn--submit, .btn--submit--edit, .btn--send {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: #00c8ac;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.btn:before, .btn--back:before, .btn--contact:before, .btn--submit:before, .btn--submit--edit:before, .btn--send:before {
  content: "";
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 2;
  opacity: 0;
  background-color: rgba(74, 87, 94, 0.3);
  transition: opacity 0.3s ease;
}

@media screen and (min-width: 769px) and (min-width: 769px) {
  .btn:hover:before, .btn--back:hover:before, .btn--contact:hover:before, .btn--submit:hover:before, .btn--submit--edit:hover:before, .btn--send:hover:before {
    opacity: 1;
  }
}

.btn_ico, .btn_ico--contact {
  display: block;
  background: url("../img/index/ico_mail.svg") no-repeat center;
  background-size: contain;
}

.btn, .btn--back {
  padding: 20px;
  font-size: 20px;
  font-size: 1.39092vw;
  border: none;
}

.btn:before, .btn--back:before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.btn--contact {
  width: 100%;
  height: 100%;
  border: none;
  background: url("../img/index/mail_off.png") no-repeat center;
  background-size: contain;
  transition: background-image 0.3s;
}

@media screen and (max-width: 768px) {
  .btn--contact {
    height: 100%;
    font-size: 14px;
    font-size: 0.95168vw;
  }
}

@media screen and (min-width: 769px) {
  .btn--contact:hover {
    background-image: url("../img/index/mail_on.png");
  }
  .btn--contact:hover:before {
    display: none;
  }
}

.btn--submit, .btn--submit--edit {
  width: 100%;
  height: 100%;
  padding: 6%;
  font-size: 20px;
  font-size: 1.39092vw;
  border: none;
  box-shadow: none;
  -webkit-appearance: none;
  background: url("../img/index/confirm_off.png") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 769px) {
  .btn--submit, .btn--submit--edit {
    transition: background-image 0.3s ease;
  }
}

@media screen and (min-width: 769px) {
  .btn--submit:hover, .btn--submit--edit:hover {
    background: url("../img/index/confirm_on.png") no-repeat center;
    background-size: contain;
  }
}

.btn--submit:hover:before, .btn--submit--edit:hover:before {
  display: none;
}

.btn--submit:hover:disabled, .btn--submit--edit:hover:disabled, .btn--submit:disabled, .btn--submit--edit:disabled {
  background: url("../img/index/confirm_not.png") no-repeat center;
  background-size: contain;
}

.btn--submit:disabled, .btn--submit--edit:disabled {
  pointer-events: none;
}

.btn--submit--edit {
  background: url("../img/index/edit_off.png") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 769px) and (min-width: 769px) {
  .btn--submit--edit:hover {
    background: url("../img/index/edit_on.png") no-repeat center;
    background-size: contain;
  }
}

@media screen and (min-width: 769px) {
  .btn--submit--edit:hover:before {
    display: none;
  }
}

.btn--send {
  width: 100%;
  height: 100%;
  padding: 6%;
  font-size: 20px;
  font-size: 1.39092vw;
  border: none;
  box-shadow: none;
  -webkit-appearance: none;
  background: url("../img/index/send_off.png") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 769px) {
  .btn--send {
    transition: background-image 0.3s ease;
  }
}

@media screen and (min-width: 769px) and (min-width: 769px) {
  .btn--send:hover {
    background: url("../img/index/send_on.png") no-repeat center;
    background-size: contain;
  }
}

@media screen and (min-width: 769px) {
  .btn--send:hover:before {
    display: none;
  }
}

.btn--back {
  background: none;
  font-size: 20px;
  background-color: #fff;
  border: 3px solid #00c8ac;
}

@media screen and (max-width: 768px) {
  .btn--back {
    font-size: 16px;
    border: 3px solid #00c8ac;
  }
}

.btn_ico {
  width: 22px;
  height: 17px;
  margin-right: 14px;
}

.btn_ico--contact {
  width: 31px;
  height: 23px;
  margin-right: 20px;
}

.c-btnMore, .c-btnBack {
  width: 80%;
  max-width: 240px;
  margin: 40px auto 0;
}

.c-btnMore a, .c-btnBack a {
  display: block;
  width: 100%;
  background: #fff;
  font-size: 20px;
  font-size: 2;
  font-weight: bold;
  text-align: center;
  color: #00c8ac;
  border: 3px solid #00c8ac;
  padding: 14px 0;
  position: relative;
  transition: .3s;
}

.c-btnMore a::before, .c-btnBack a::before, .c-btnMore a::after, .c-btnBack a::after {
  content: "";
  display: block;
  width: 10%;
  height: 0;
  padding-top: 10%;
  position: absolute;
  top: 50%;
  right: 4%;
  transform: translateY(-50%);
  transition: .3s;
}

.c-btnMore a::before, .c-btnBack a::before {
  background: url(../img/common/btn_icon_02.svg) center center/contain no-repeat #00c8ac;
  border-radius: 50%;
  opacity: 1;
}

.c-btnMore a::after, .c-btnBack a::after {
  background: url(../img/common/btn_icon_white.svg) center center/contain no-repeat #fff;
  border-radius: 50%;
  opacity: 0;
}

@media screen and (min-width: 769px) {
  .c-btnMore a:hover, .c-btnBack a:hover {
    background: #00c8ac;
    color: #fff;
  }
  .c-btnMore a:hover::before, .c-btnBack a:hover::before {
    opacity: 0;
  }
  .c-btnMore a:hover::after, .c-btnBack a:hover::after {
    opacity: 1;
  }
}

.c-btnBack a::before, .c-btnBack a::after {
  transform: translateY(-50%) rotate(180deg);
  right: auto;
  left: 4%;
}

.title {
  font-size: 38px;
  line-height: 1;
  color: #4a575e;
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .title {
    font-size: 3.15vw;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 24px;
  }
}

.title--about {
  margin-bottom: 2vw;
  line-height: 1.73;
  color: #00b09c;
  font-size: 38px;
  text-align: center;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 1200px) {
  .title--about {
    font-size: 3.15vw;
    line-height: 1.5;
  }
}

@media screen and (max-width: 768px) {
  .title--about {
    margin-bottom: 5%;
    font-size: 24px;
  }
}

.title--single {
  font-size: 50px;
  line-height: 1.74;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 1200px) {
  .title--single {
    font-size: 4.18vw;
  }
}

@media screen and (max-width: 768px) {
  .title--single {
    font-size: 28px;
  }
}

.titleBox {
  margin-bottom: 30px;
}

@media screen and (min-width: 1200px) {
  .titleBox {
    margin: 0 0 2%;
  }
}

@media screen and (max-width: 768px) {
  .titleBox {
    margin: 30px 0;
  }
}

.title_ico {
  width: 100%;
  max-width: 90px;
  margin: 0 auto 2.4vw;
}

@media screen and (min-width: 1200px) {
  .title_ico {
    max-width: none;
    width: 7.5vw;
    margin: 0 auto 3%;
  }
}

@media screen and (max-width: 768px) {
  .title_ico {
    width: 13vw;
    margin: 0 auto 7%;
  }
}

.title_ico--contact {
  width: 3.3vw;
  margin: 0 auto 2vw;
}

@media screen and (max-width: 768px) {
  .title_ico--contact {
    width: 11%;
    margin: 0 auto 7%;
  }
}

.titleSub {
  margin: 40px auto 30px;
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .titleSub {
    margin: 1% 0;
  }
}

@media screen and (max-width: 768px) {
  .titleSub {
    margin: 35px auto 0;
    padding: 0;
  }
}

.titleSub--lead {
  margin: 0 auto 30px;
}

.titleSub_text {
  width: 200px;
  padding: 0 0 16px;
  display: inline-block;
  font-size: 24px;
  color: #00b09c;
  text-align: center;
  background: url("../img/index/title_border.png") no-repeat right bottom;
  background-size: contain;
}

@media screen and (min-width: 1200px) {
  .titleSub_text {
    width: auto;
    padding: 0 3% 1.5%;
    font-size: 2vw;
  }
}

@media screen and (max-width: 768px) {
  .titleSub_text {
    font-size: 20px;
  }
}

.c-ttl {
  text-align: center;
  margin-top: 0;
}

.c-ttl_icon {
  width: 90px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .c-ttl_icon {
    width: 78px;
  }
}

.c-ttl_txt {
  font-size: 38px;
  font-size: 2.70864vw;
  color: #4A575E;
  margin-top: 26px;
}

@media screen and (max-width: 768px) {
  .c-ttl_txt {
    font-size: 26px;
    font-size: 2.6;
    margin-top: 22px;
  }
}

.text {
  font-size: 16px;
}

@media screen and (min-width: 1200px) {
  .text {
    font-size: 1.34vw;
  }
}

.text--small {
  font-size: 12px;
  font-size: 0.80527vw;
}

.text--about {
  display: inline-block;
  font-size: 16px;
  line-height: 2.2;
  text-align: center;
  letter-spacing: 0.075em;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .text--about {
    font-size: 1.35vw;
  }
}

@media screen and (max-width: 768px) {
  .text--about {
    line-height: 2;
    text-align: left;
  }
}

.text--about span {
  font-size: 12px;
  font-size: 1.2;
  vertical-align: top;
}

.text--note {
  font-size: 15px;
  line-height: 2.8;
}

@media screen and (min-width: 1200px) {
  .text--note {
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 768px) {
  .text--note {
    font-size: 14px;
  }
}

.text--promise {
  font-size: 16px;
  line-height: 1.2;
  text-indent: -1em;
  padding-left: 1.5em;
}

@media screen and (min-width: 1200px) {
  .text--promise {
    font-size: 1.34vw;
  }
}

@media screen and (max-width: 768px) {
  .text--promise {
    line-height: 1.6;
  }
}

.text--info {
  font-size: 16px;
  line-height: 1.95;
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .text--info {
    font-size: 1.34vw;
  }
}

@media screen and (max-width: 768px) {
  .text--info {
    padding: 0 8%;
    box-sizing: border-box;
    line-height: 1.8;
    text-align: left;
  }
}

.text--white {
  font-size: 16px;
  line-height: 1.2;
  color: #fff;
}

@media screen and (min-width: 1200px) {
  .text--white {
    font-size: 1.34vw;
  }
}

@media screen and (max-width: 768px) {
  .text--white {
    line-height: 1.6;
  }
}

.text--green {
  font-size: 16px;
  line-height: 1.2;
  color: #00c8ac;
}

@media screen and (min-width: 1200px) {
  .text--green {
    font-size: 1.34vw;
  }
}

@media screen and (max-width: 768px) {
  .text--green {
    line-height: 1.6;
  }
}

.ballon, .ballon--reverse {
  width: 100%;
  display: flex;
  align-items: center;
}

.ballon_text--left--green, .ballon_text--left--white, .ballon_text--right--white {
  padding: 10px 20px;
  position: relative;
  border-radius: 50px;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95), transform 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.is-view.ballon_text--left--green, .is-view.ballon_text--left--white, .is-view.ballon_text--right--white {
  opacity: 1;
  transform: scale(1);
}

.ballon_text--left--green:before, .ballon_text--left--white:before, .ballon_text--right--white:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ballon--reverse {
  flex-direction: row-reverse;
}

.ballon_ico {
  width: 4.2vw;
}

@media screen and (max-width: 768px) {
  .ballon_ico {
    width: 22%;
  }
}

.ballon_text--left--green {
  margin-left: 20px;
  color: #fff;
  background-color: #00c8ac;
}

@media screen and (max-width: 768px) {
  .ballon_text--left--green {
    width: 70%;
  }
}

.ballon_text--left--green:before {
  border-width: 6px 20px 6px 0;
  border-color: transparent #00c8ac transparent transparent;
  left: -19px;
}

.ballon_text--left--white {
  margin-left: 20px;
  color: #00b09c;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .ballon_text--left--white {
    width: 70%;
  }
}

.ballon_text--left--white:before {
  border-width: 6px 20px 6px 0;
  border-color: transparent #fff transparent transparent;
  left: -19px;
}

.ballon_text--right--white {
  margin-right: 20px;
  color: #00b09c;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .ballon_text--right--white {
    width: 70%;
  }
}

.ballon_text--right--white:before {
  border-width: 6px 0 6px 20px;
  border-color: transparent transparent transparent #fff;
  right: -19px;
}

.c-tag {
  display: block;
  width: 110px;
  background: #4A575E;
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.5em;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

.underlayer {
  padding-top: 100px;
}

@media screen and (max-width: 768px) {
  .underlayer {
    padding-top: 8vh;
  }
}

.underlayer * {
  box-sizing: border-box;
}

.underlayer .cu-sec {
  width: 100%;
}

.underlayer .cu-sec:nth-of-type(2) {
  padding-top: 44px;
}

.underlayer .cu-sec_inner {
  max-width: 960px;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 58px;
}

.underlayer .cu-sec_inner.type-mini {
  max-width: 704px;
}

.cu-mv {
  width: 100%;
}

.cu-mv_cont {
  height: calc(396 / 1366 * 100vw);
  background: #b3b9bb;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .cu-mv_cont {
    height: 373px;
  }
}

.cu-mv_ttl {
  font-size: 52.8px;
  font-size: 3.79209vw;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 5px #231815;
}

@media screen and (max-width: 768px) {
  .cu-mv_ttl {
    font-size: 38px;
    font-size: 3.8;
  }
}

.cu-mv_txt {
  width: 95%;
  font-size: 20px;
  font-size: 2;
  line-height: 1.8em;
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  margin-top: calc(76 / 1366 * 100vw);
}

@media screen and (max-width: 768px) {
  .cu-mv_txt {
    font-size: 16px;
    font-size: 1.6;
    line-height: 2em;
  }
}

.cu-mv_breadcrumd {
  max-width: 960px;
  width: 90%;
  padding: 0 18px;
  margin: 18px auto 0;
}

.storyPage .cu-mv_cont {
  background: url(../img/story/mv_bg.jpg) 84% center/cover no-repeat;
}

.newsPage .cu-mv_cont {
  background: url(../img/news/mv_bg.jpg) 14% center/cover no-repeat;
}

.featurePage .cu-mv_cont {
  background: url(../img/feature/mv_bg.jpg) center center/cover no-repeat;
}

.contactPage .cu-mv_cont {
  background: url(../img/contact/mv_bg.jpg) center center/cover no-repeat;
}

.informationPage .cu-mv_cont {
  background: url(../img/information/mv_bg.jpg) center center/cover no-repeat;
}

.documentPage .cu-mv_cont {
  background: url(../img/document/mv_bg.jpg) center center/cover no-repeat;
}

.videoPage .cu-mv_cont {
  background: url(../img/video/mv_bg.jpg) center center/cover no-repeat;
}

.videoSingle .cu-mv_cont {
  background: url(../img/video/mv_bg.jpg) center center/cover no-repeat;
}

.cu-form {
  max-width: 870px;
  width: 100%;
  margin: 38px auto 0;
}

.cu-form table {
  display: block;
}

.cu-form table tbody {
  display: block;
}

.cu-form table tbody tr {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr {
    display: block;
  }
}

.cu-form table tbody tr + tr {
  margin-top: 4px;
}

.cu-form table tbody tr th {
  display: block;
  width: 272px;
  display: flex;
  align-items: center;
  background: #00b09c;
  font-size: 18px;
  font-size: 1.8;
  line-height: 1.5em;
  font-weight: bold;
  color: #fff;
  padding-left: 4%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr th {
    width: 100%;
    padding: 20px 0;
    padding-left: 4%;
  }
}

.cu-form table tbody tr th.required::after {
  content: "";
  display: inline-block;
  width: 20%;
  padding-top: calc(28 / 56 * 20%);
  background: url(../img/common/form_required.svg) center center/contain no-repeat;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr th.required::after {
    width: 55px;
    padding-top: 27px;
    position: static;
    transform: none;
    margin-left: 20px;
  }
}

.cu-form table tbody tr td {
  display: block;
  width: calc(100% - 272px - 20px);
  padding: 14px 0;
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr td {
    width: 100%;
  }
}

.cu-form table tbody tr td span {
  display: none;
  font-size: 15px;
  font-size: 1.5;
  line-height: 1.5em;
  margin-top: 4px;
}

.cu-form table tbody tr td .inner + .inner {
  margin-top: 22px;
}

.cu-form table tbody tr td .selectbox_inner {
  width: calc(148 / 568 * 100%);
  position: relative;
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr td .selectbox_inner {
    width: 100%;
  }
}

.cu-form table tbody tr td .selectbox_inner::before {
  content: "";
  display: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 6px 0 6px;
  border-color: #4A575E transparent transparent transparent;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr td .selectbox_inner::before {
    border-width: 14px 8px 0 8px;
  }
}

.cu-form table tbody tr td .formCommon, .cu-form table tbody tr td input[type="text"],
.cu-form table tbody tr td input[type="email"],
.cu-form table tbody tr td input[type="tel"], .cu-form table tbody tr td textarea, .cu-form table tbody tr td select {
  font-size: 19px;
  font-size: 1.9;
  display: block;
  border: 1px solid #b3b9bb;
  border-radius: 4px;
  padding: 0 12px;
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr td .formCommon, .cu-form table tbody tr td input[type="text"],
  .cu-form table tbody tr td input[type="email"],
  .cu-form table tbody tr td input[type="tel"], .cu-form table tbody tr td textarea, .cu-form table tbody tr td select {
    font-size: 16px;
    font-size: 1.6;
    padding: 12px;
  }
}

.cu-form table tbody tr td input[type="text"],
.cu-form table tbody tr td input[type="email"],
.cu-form table tbody tr td input[type="tel"] {
  width: calc(148 / 568 * 100%);
  height: 35px;
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr td input[type="text"],
  .cu-form table tbody tr td input[type="email"],
  .cu-form table tbody tr td input[type="tel"] {
    width: 100%;
    height: 40px;
  }
}

.cu-form table tbody tr td input[type="text"].type01,
.cu-form table tbody tr td input[type="email"].type01,
.cu-form table tbody tr td input[type="tel"].type01 {
  width: calc(302 / 568 * 100%);
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr td input[type="text"].type01,
  .cu-form table tbody tr td input[type="email"].type01,
  .cu-form table tbody tr td input[type="tel"].type01 {
    width: 100%;
  }
}

.cu-form table tbody tr td textarea {
  width: 100%;
  height: 200px;
  padding: 6px 12px;
}

.cu-form table tbody tr td select {
  width: 100%;
  padding: 4px 12px;
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr td select {
    width: 100%;
  }
}

.cu-form table tbody tr td input[type="radio"] {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-color: #00c8ac;
  display: none;
}

.cu-form table tbody tr td .mwform-radio-field {
  display: inline-block;
  border: 1px solid #b3b9bb;
  border-radius: 4px;
  padding: 0 8px;
  margin-right: 28px;
  margin-left: 0 !important;
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr td .mwform-radio-field {
    display: block;
    margin-right: 0;
  }
}

.cu-form table tbody tr td .mwform-radio-field label {
  height: 100%;
  display: flex;
  align-items: center;
}

.cu-form table tbody tr td .mwform-radio-field span {
  display: block;
  font-size: 19px;
  font-size: 1.9;
  margin-top: 0;
  position: relative;
  padding: 5px 0 5px 28px;
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr td .mwform-radio-field span {
    font-size: 16px;
    font-size: 1.6;
    padding: 12px 0 12px 32px;
  }
}

.cu-form table tbody tr td .mwform-radio-field span::before {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #4A575E;
  left: 0;
}

.cu-form table tbody tr td .mwform-radio-field span::after, .cu-form table tbody tr td .mwform-radio-field span::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cu-form table tbody tr td .mwform-radio-field span::after {
  width: 12px;
  height: 12px;
  background: #00c8ac;
  border-radius: 50%;
  left: 4px;
  opacity: 0;
}

.cu-form table tbody tr td .mwform-radio-field input {
  margin-top: 0;
}

.cu-form table tbody tr td .mwform-radio-field input:checked + span::after {
  opacity: 1;
}

.cu-form table tbody tr td #zip {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr td #zip {
    width: calc(100% - 91px);
  }
}

.cu-form table tbody tr td #zip + p {
  display: none;
}

.cu-form table tbody tr td #btnAddress {
  display: none;
  width: 86px;
  height: 35px;
  background: #4A575E;
  font-size: 15px;
  font-size: 1.5;
  line-height: 35px;
  text-align: center;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .cu-form table tbody tr td #btnAddress {
    height: 40px;
    line-height: 40px;
  }
}

.cu-form_txt {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1em;
}

.cu-form_inner {
  margin-top: 12px;
}

.cu-form_consent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
}

.cu-form_consent .check {
  display: block;
  width: 27px;
  height: 27px;
  border: 1px solid #231815;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .cu-form_consent .check {
    margin-right: 16px;
  }
}

.cu-form_consent_txt {
  font-size: 17px;
  font-size: 1.7;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .cu-form_consent_txt {
    font-size: 16px;
    font-size: 1.6;
  }
}

.cu-form_consent_txt a {
  color: #2887cd;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .cu-form_consent_txt a:hover {
    text-decoration: underline;
  }
}

.cu-form_btm {
  max-width: 604px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .cu-form_btm {
    flex-direction: column-reverse;
    margin-top: 20px;
  }
}

.cu-form_btm input[type="submit"],
.cu-form_btm button[type="submit"] {
  display: block;
  width: 272px;
  background: #fff;
  font-size: 20px;
  font-size: 2;
  font-weight: bold;
  text-align: center;
  color: #00c8ac;
  border: 3px solid #00c8ac;
  border-radius: 0;
  padding: 18px 0;
  margin: 62px auto 0;
  transition: .3s;
}

@media screen and (min-width: 769px) {
  .cu-form_btm input[type="submit"]:hover,
  .cu-form_btm button[type="submit"]:hover {
    background: #00c8ac;
    color: #fff;
  }
}

.cu-form_btm input[type="submit"] + input[type="submit"],
.cu-form_btm button[type="submit"] + input[type="submit"] {
  margin-left: 30px;
}

.cu-form_btm input[type="submit"].second,
.cu-form_btm button[type="submit"].second {
  margin-top: 20px;
}

.cu-form.type-mini {
  max-width: 604px;
  margin-top: 62px;
}

.cu-form.type-mini td input[type="text"].type01,
.cu-form.type-mini td input[type="email"].type01,
.cu-form.type-mini td input[type="tel"].type01 {
  width: 100%;
}

.cu-form_top_txt {
  width: 95%;
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.8em;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .cu-form_top_txt {
    font-size: 14px;
    font-size: 1.4;
  }
}

.cu-form_top_txt.type02 {
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .cu-form_top_txt.type02 {
    margin-top: 30px;
  }
}

.cu-form_top_telArea {
  background: #e4f8f4;
  padding: 42px 0 52px;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .cu-form_top_telArea {
    padding: 22px 0 32px;
  }
}

.cu-form_top_telArea_inner {
  max-width: 570px;
  width: 95%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .cu-form_top_telArea_inner {
    width: 85%;
  }
}

.cu-form_top_telArea_tel {
  display: flex;
  align-items: center;
}

.cu-form_top_telArea_tel::before {
  content: "";
  display: inline-block;
  width: 56px;
  height: 36px;
  background: url(../img/common/tel_icon.svg) center center/contain no-repeat;
  margin-right: 20px;
}

.cu-form_top_telArea_tel a {
  font-size: 38px;
  font-size: 3.8;
  font-weight: bold;
  color: #231815;
}

@media screen and (max-width: 768px) {
  .cu-form_top_telArea_tel a {
    font-size: 24px;
    font-size: 2.4;
  }
}

@media screen and (min-width: 769px) {
  .cu-form_top_telArea_tel a {
    pointer-events: none;
    text-decoration: none;
  }
}

.cu-form_top_telArea_txt {
  font-size: 20px;
  font-size: 2;
  line-height: 1em;
  margin-top: 26px;
}

@media screen and (max-width: 768px) {
  .cu-form_top_telArea_txt {
    font-size: 14px;
    font-size: 1.4;
    line-height: 1.5em;
    margin-top: 14px;
  }
}

.cu-form_top_telArea_txt + .contact_top_telArea_txt {
  margin-top: 22px;
}

.cu-form_top_stage {
  max-width: 790px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px auto 0;
  position: relative;
}

.cu-form_top_stage::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #00b09c;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.cu-form_top_stage_cmn {
  width: 15px;
  height: 15px;
  background: #00b09c;
  border-radius: 50%;
  border: 1px solid #00b09c;
  position: relative;
}

.cu-form_top_stage_cmn span {
  font-size: 16px;
  font-size: 1.6;
  white-space: nowrap;
  line-height: 1.5em;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}

.mw_wp_form_input #btnAddress {
  display: inline-block !important;
}

.mw_wp_form_input tr td span {
  display: inline-block !important;
}

.mw_wp_form_input .selectbox_inner::before {
  display: block !important;
}

.display-input.none {
  display: none;
}

.display-complete.none {
  display: none;
}

.cu-breadcrumbs {
  font-size: 14px;
  font-size: 1.4;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .cu-breadcrumbs {
    overflow: hidden;
  }
}

.cu-breadcrumbs span[property="name"] {
  display: inline-block;
  padding: 0;
  margin-top: -3px;
  vertical-align: middle;
  max-width: 25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
  .cu-breadcrumbs span[property="name"] {
    max-width: 8em;
  }
}

.cu-breadcrumbs a {
  color: #231815;
}

.cu-breadcrumbs .home {
  display: inline-block;
  width: 14px;
  height: 15px;
  background: url(../img/common/breadcrumbs_icon.svg) center center/contain no-repeat;
}

.cu-breadcrumbs .home span {
  display: none;
}

.cu-breadcrumbs .direction {
  display: inline-block;
  width: 9px;
  height: 12px;
  background: url(../img/common/breadcrumbs_direction.svg) center center/contain no-repeat;
  margin: 0 8px;
}

.topPage .mv_frame--right, .topPage .mv_frame--bottom, .topPage .mv_frame--left {
  background-color: #00c8ac;
  position: fixed;
  z-index: 9990;
}

.topPage .mv {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #00c8ac;
  position: relative;
}

@media screen and (min-width: 769px) {
  .topPage .mv {
    width: 100vw;
    width: 100vw;
    height: calc(100vh - 35px - 100px);
    padding-right: 35px;
    padding-left: 35px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .topPage .mv {
    width: 100%;
    height: calc(100% - 4vw);
  }
}

@media screen and (max-width: 768px) {
  .topPage .mv {
    height: 100vh;
    overflow: hidden;
  }
}

.topPage .mv_lead {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 769px) {
  .topPage .mv_lead {
    max-width: 1100px;
  }
}

@media screen and (max-width: 768px) {
  .topPage .mv_lead {
    width: 88%;
    top: 15%;
    transform: translateX(-50%);
  }
}

.topPage .mv_video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .topPage .mv_video {
    width: 90%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 8px solid #00c8ac;
    background: #00c8ac;
    overflow: visible;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -9999;
    opacity: 0;
  }
}

.topPage .mv_video.is-view {
  z-index: 9999;
  opacity: 1;
}

.topPage .mv_video video {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 39px));
}

@media screen and (max-width: 768px) {
  .topPage .mv_video video {
    width: 100%;
    height: 100%;
    position: static;
    transform: translate(0, 0);
  }
}

@media screen and (min-width: 769px) {
  .topPage .mv_videoBtn {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .topPage .mv_videoBtn {
    width: 15%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

@media screen and (max-width: 768px) {
  .topPage .mv_videoBtn.is-hide {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .topPage .mv_videoClose {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .topPage .mv_videoClose {
    width: 8vw;
    height: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8vw;
    color: #00c8ac;
    line-height: 1;
    background: #fff;
    position: absolute;
    bottom: calc(100% + 8px);
    right: -8px;
  }
}

.topPage .mv_news {
  width: 100%;
  background: #287C73;
  text-align: right;
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 9998;
}

@media screen and (max-width: 768px) {
  .topPage .mv_news {
    width: 100%;
    background: rgba(35, 24, 21, 0.6);
    top: 8vh;
    left: 0;
    transform: none;
    z-index: 100;
  }
}

.topPage .mv_news_inner {
  height: 40px;
  padding: 0 calc(44 / 1366 * 100vw);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .topPage .mv_news_inner {
    padding: 0 10px;
    justify-content: flex-start;
  }
}

.topPage .mv_news p {
  font-size: 18px;
  font-size: 1.8;
  font-weight: bold;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .topPage .mv_news p {
    font-size: 14px;
    font-size: 1.4;
  }
}

.topPage .mv_news_ttl {
  margin-right: 30px;
  position: relative;
  padding-bottom: 1px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .topPage .mv_news_ttl {
    display: flex;
    width: 94px;
    white-space: nowrap;
  }
}

.topPage .mv_news_ttl::before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 17px;
  background: url(../img/index/mv_news_icon.svg) center center/contain no-repeat;
  margin-right: 6px;
}

.topPage .mv_news_txt {
  display: inline-block;
}

.topPage .mv_news_txt span {
  border-bottom: 1px solid #fff;
}

@media screen and (max-width: 768px) {
  .topPage .mv_news_txt {
    width: calc(100% - 100px);
    text-align: left;
    line-height: 1.3em;
  }
}

.topPage .mv_arrow {
  width: 4%;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 40px;
  left: 0;
  z-index: 9991;
}

@media screen and (min-width: 769px) {
  .topPage .mv_arrow {
    max-width: 77px;
  }
}

@media screen and (min-width: 1200px) {
  .topPage .mv_arrow {
    max-width: none;
    width: 4vw;
  }
}

@media screen and (max-width: 768px) {
  .topPage .mv_arrow {
    display: none;
  }
}

.topPage .mv_arrowIco {
  display: block;
  animation: arrowAnime 1s infinite alternate;
}

@keyframes arrowAnime {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.topPage .mv_arrowText {
  width: 100%;
  margin-bottom: 25px;
  padding-right: 2px;
  color: #fff;
}

.topPage .mv_frame--right {
  width: 35px;
  height: 100vh;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .topPage .mv_frame--right {
    display: none;
  }
}

.topPage .mv_frame--bottom {
  width: 100vw;
  height: 35px;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 768px) {
  .topPage .mv_frame--bottom {
    display: none;
  }
}

.topPage .mv_frame--left {
  width: 35px;
  height: 100vh;
  top: 0;
  left: 0;
}

@media screen and (max-width: 768px) {
  .topPage .mv_frame--left {
    display: none;
  }
}

.topPage .mv_img {
  width: 100%;
  height: 100vh;
  background: url("../img/index/mv.jpg") no-repeat center top;
  background-size: cover;
}

.topPage .mv_reload {
  width: 6%;
  position: absolute;
  z-index: 3;
  right: 70px;
  bottom: 30px;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .topPage .mv_reload {
    max-width: 110px;
    transition: opacity 0.3s ease;
  }
}

@media screen and (min-width: 1200px) {
  .topPage .mv_reload {
    max-width: none;
    width: 6vw;
  }
}

@media screen and (max-width: 768px) {
  .topPage .mv_reload {
    width: 45%;
    margin: 0 auto;
    right: 0;
    bottom: 5.7%;
    left: 0;
  }
}

@media screen and (min-width: 769px) {
  .topPage .mv_reload:hover {
    opacity: 0.7;
  }
}

@media screen and (min-width: 769px) {
  .topPage .about {
    width: 100vw;
    width: 100vw;
    height: calc(100vh - 35px - 100px);
    padding-right: 35px;
    padding-left: 35px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .topPage .about {
    width: 100%;
    height: calc(100% - 4vw);
  }
}

@media screen and (max-width: 768px) {
  .topPage .about {
    overflow: hidden;
  }
}

.topPage .about_cont {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .topPage .about_cont {
    width: 100%;
    height: 100%;
    padding: 60px 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;
    background: none;
  }
}

.topPage .about_box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 768px) {
  .topPage .about_box {
    padding: 0 5%;
    box-sizing: border-box;
  }
}

.topPage .about_text {
  display: block;
  text-align: right;
  font-size: 12px;
  font-size: 1.2;
}

@media screen and (min-width: 1200px) {
  .topPage .about_text {
    font-size: .95vw;
  }
}

@media screen and (max-width: 768px) {
  .topPage .about_text {
    width: 100%;
    display: block;
    position: static;
  }
}

.topPage .promise {
  background-color: #e4f8f4;
}

@media screen and (min-width: 769px) {
  .topPage .promise {
    width: 100vw;
    width: 100vw;
    height: calc(100vh - 35px - 100px);
    padding-right: 35px;
    padding-left: 35px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .topPage .promise {
    width: 100%;
    height: calc(100% - 4vw);
  }
}

@media screen and (max-width: 768px) {
  .topPage .promise {
    padding-bottom: 60px;
  }
}

.topPage .promise .c-ttl_icon {
  width: 258px;
}

@media screen and (max-width: 768px) {
  .topPage .promise .c-ttl_icon {
    width: 170px;
  }
}

.topPage .promise .c-btnMore, .topPage .promise .c-btnBack {
  margin-top: 90px;
}

@media screen and (max-width: 768px) {
  .topPage .promise .c-btnMore, .topPage .promise .c-btnBack {
    margin-top: 60px;
  }
}

.topPage .promise_box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 769px) {
  .topPage .promise_box {
    max-width: 660px;
  }
}

@media screen and (min-width: 1200px) {
  .topPage .promise_box {
    max-width: none;
  }
}

.topPage .promise_cont {
  margin-top: 80px;
}

@media screen and (max-width: 768px) {
  .topPage .promise_cont {
    margin-top: 40px;
    padding: 0 8%;
    box-sizing: border-box;
  }
}

.topPage .promise_list + .promise_list {
  margin-top: 10.5%;
}

@media screen and (min-width: 1200px) {
  .topPage .promise_list + .promise_list {
    margin-top: 7%;
  }
}

@media screen and (max-width: 768px) {
  .topPage .promise_list + .promise_list {
    margin-top: 11%;
  }
}

.topPage .promisePic {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 769px) {
  .topPage .promisePic {
    width: 100vw;
    width: 100vw;
    height: calc(100vh - 35px - 100px);
    padding-right: 35px;
    padding-left: 35px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .topPage .promisePic {
    width: 100%;
    height: calc(100% - 4vw);
  }
}

@media screen and (max-width: 768px) {
  .topPage .promisePic {
    display: block;
  }
}

.topPage .promisePic_box {
  width: 50%;
  height: 50%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .topPage .promisePic_box {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
}

.topPage .promisePic_img--01 {
  width: 100%;
  height: 100%;
  background: url("../img/index/promise_pic01.jpg") no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .topPage .promisePic_img--01 {
    background: none;
  }
}

.topPage .promisePic_img--02 {
  width: 100%;
  height: 100%;
  background: url("../img/index/promise_pic02.jpg") no-repeat right center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .topPage .promisePic_img--02 {
    background: none;
  }
}

.topPage .promisePic_img--03 {
  width: 100%;
  height: 100%;
  background: url("../img/index/promise_pic03.jpg") no-repeat left bottom;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .topPage .promisePic_img--03 {
    background: none;
  }
}

.topPage .promisePic_img--04 {
  width: 100%;
  height: 100%;
  background: url("../img/index/promise_pic04.jpg") no-repeat right center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .topPage .promisePic_img--04 {
    background: none;
  }
}

.topPage .promisePic_img--05 {
  width: 100%;
  height: 100%;
  background: url("../img/index/promise_pic05.jpg") no-repeat center top;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .topPage .promisePic_img--05 {
    background: none;
  }
}

.topPage .promisePic_img--06 {
  width: 100%;
  height: 100%;
  background: url("../img/index/promise_pic06.jpg") no-repeat right top;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .topPage .promisePic_img--06 {
    background: none;
  }
}

.topPage .promisePic_img--07 {
  width: 100%;
  height: 100%;
  background: url("../img/index/promise_pic07.jpg") no-repeat left top;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .topPage .promisePic_img--07 {
    background: none;
  }
}

.topPage .promisePic_img--08 {
  width: 100%;
  height: 100%;
  background: url("../img/index/promise_pic08.jpg") no-repeat right top;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .topPage .promisePic_img--08 {
    background: none;
  }
}

.topPage .promise_ballon--top--left {
  position: absolute;
  top: 15px;
  left: 20px;
}

@media screen and (max-width: 768px) {
  .topPage .promise_ballon--top--left {
    width: 90%;
    top: auto;
    bottom: 15px;
  }
}

.topPage .promise_ballon--top--right {
  position: absolute;
  top: 15px;
  right: 20px;
}

@media screen and (max-width: 768px) {
  .topPage .promise_ballon--top--right {
    width: 90%;
  }
}

.topPage .promise_ballon--bottom--left {
  position: absolute;
  bottom: 15px;
  left: 20px;
}

@media screen and (max-width: 768px) {
  .topPage .promise_ballon--bottom--left {
    width: 90%;
  }
}

.topPage .promise_ballon--bottom--right {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

@media screen and (max-width: 768px) {
  .topPage .promise_ballon--bottom--right {
    width: 90%;
  }
}

.topPage .promise_sub {
  width: 222px;
  margin: -10% auto 5%;
}

@media screen and (min-width: 1200px) {
  .topPage .promise_sub {
    width: 18.5vw;
  }
}

@media screen and (max-width: 768px) {
  .topPage .promise_sub {
    width: 40%;
    margin: 0 auto;
  }
}

.topPage .promise_number {
  width: 2vw;
  margin: 0% 2% -3px 0;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .topPage .promise_number {
    width: 4%;
    margin-right: 1%;
  }
}

.topPage .promiseMv {
  background: url("../img/index/promise_mv.jpg") no-repeat center 0;
  background-size: cover;
}

@media screen and (min-width: 769px) {
  .topPage .promiseMv {
    width: 100vw;
    width: 100vw;
    height: calc(100vh - 35px - 100px);
    padding-right: 35px;
    padding-left: 35px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .topPage .promiseMv {
    width: 100%;
    height: calc(100% - 4vw);
  }
}

.topPage .promiseMv--pattern02 {
  background: url("../img/index/promise_mv02.jpg") no-repeat center 0;
  background-size: cover;
}

@media screen and (min-width: 769px) {
  .topPage .promiseMv--pattern02 {
    width: 100vw;
    width: 100vw;
    height: calc(100vh - 35px - 100px);
    padding-right: 35px;
    padding-left: 35px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .topPage .promiseMv--pattern02 {
    width: 100%;
    height: calc(100% - 4vw);
  }
}

.topPage .promiseSimple {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  background-color: #00c8ac;
}

@media screen and (min-width: 769px) {
  .topPage .promiseSimple {
    width: 100vw;
    width: 100vw;
    height: calc(100vh - 35px - 100px);
    padding-right: 35px;
    padding-left: 35px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .topPage .promiseSimple {
    width: 100%;
    height: calc(100% - 4vw);
  }
}

@media screen and (max-width: 768px) {
  .topPage .promiseSimple {
    padding: 17% 0;
  }
}

.topPage .promise_label {
  padding: 2px 0;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-left: 1em;
}

@media screen and (max-width: 768px) {
  .topPage .promise_label {
    display: inline;
    padding-left: 0;
    background: #f2e479;
  }
}

.topPage .promise_label:after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #f2e479;
  transition: width 1s ease 0.3s;
}

@media screen and (max-width: 768px) {
  .topPage .promise_label:after {
    display: none;
  }
}

.topPage .promise_cont.is-view .promise_label:after {
  width: 100%;
}

.topPage .case {
  max-width: 960px;
  width: 90%;
  margin: 0 auto;
}

.topPage .case_cont {
  width: 100%;
}

.topPage .case_cont_ttl {
  font-size: 24px;
  font-size: 2.4;
  font-weight: bold;
  text-align: center;
  color: #00b09c;
  margin-top: 42px;
}

.topPage .case_cont_article {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

@media screen and (max-width: 768px) {
  .topPage .case_cont_article {
    display: block;
    margin-top: 16px;
  }
}

.topPage .case_cont_article_cmn {
  width: 47%;
  background: #e4f8f4;
  box-sizing: border-box;
  border: 2px solid #00c8ac;
  padding: 16px;
}

@media screen and (max-width: 768px) {
  .topPage .case_cont_article_cmn {
    width: 100%;
  }
  .topPage .case_cont_article_cmn + .case_cont_article_cmn {
    margin-top: 26px;
  }
}

.topPage .case_cont_article_cmn_ttl {
  font-size: 20px;
  font-size: 2;
  font-weight: bold;
  margin-top: 22px;
}

.topPage .case_cont_article_cmn_txt {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.5em;
  margin-top: 14px;
}

@media screen and (max-width: 768px) {
  .topPage .case_cont_article_cmn_txt {
    line-height: 1.6em;
  }
}

.topPage .case_cont_article_link {
  text-align: right;
  margin-top: 26px;
}

.topPage .case_cont_article_link a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  font-size: 16px;
  font-size: 1.6;
  font-weight: bold;
  color: #00b09c;
}

.topPage .case_cont_article_link a::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../img/common/btn_icon.svg) center center/contain no-repeat;
  margin: 0 10px;
}

.topPage .case_cont_txt {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.5em;
  text-align: center;
  margin-top: 26px;
}

.topPage .case_cont_list {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
}

@media screen and (max-width: 768px) {
  .topPage .case_cont_list {
    display: block;
  }
}

.topPage .case_cont_list_cmn {
  width: calc(308 / 960 * 100%);
  font-size: 16px;
  font-size: 1.6;
  line-height: 2em;
  text-align: center;
  padding: 5px 0;
}

@media screen and (max-width: 768px) {
  .topPage .case_cont_list_cmn {
    width: 100%;
  }
  .topPage .case_cont_list_cmn + .case_cont_list_cmn {
    margin-top: 22px;
  }
}

.topPage .case_cont_list_cmn.type--green {
  background: #e4f8f4;
  border: 2px solid #00c8ac;
}

.topPage .case_cont_list_cmn.type--orange {
  background: #FFFDEF;
  border: 2px solid #f2e479;
}

.topPage .news {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topPage .news .c-btnMore, .topPage .news .c-btnBack {
  margin-top: 120px;
}

@media screen and (max-width: 768px) {
  .topPage .news .c-btnMore, .topPage .news .c-btnBack {
    margin-top: 60px;
  }
}

.topPage .news_list {
  width: 90%;
  max-width: 960px;
  border-bottom: 1px solid #b3b9bb;
  margin: 92px auto 0;
}

@media screen and (max-width: 768px) {
  .topPage .news_list {
    margin-top: 40px;
  }
}

.topPage .news_list, .topPage .news_list_cmn + .news_list_cmn {
  border-top: 1px solid #b3b9bb;
}

.topPage .news_list_cmn_inner {
  background: #e4f8f4;
  display: flex;
  align-items: center;
  color: #231815;
  padding: 24px calc(116 / 960 * 100%);
  transition: .2s;
}

@media screen and (max-width: 768px) {
  .topPage .news_list_cmn_inner {
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 16px 4vw 24px;
  }
}

@media screen and (min-width: 769px) {
  .topPage .news_list_cmn_inner:hover {
    background: #FFF2A7;
  }
}

.topPage .news_list_date {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1em;
}

.topPage .news_list_tag {
  width: 110px;
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.8em;
  text-align: center;
  color: #fff;
  background: #4A575E;
  margin: 0 22px;
}

@media screen and (max-width: 768px) {
  .topPage .news_list_txt {
    width: 100%;
    margin-top: 16px;
  }
}

.topPage .subArea {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .topPage .subArea {
    display: block;
  }
}

.topPage .subArea_inner {
  max-width: 760px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .topPage .subArea_inner {
    display: block;
  }
}

.topPage .subArea_inner_cmn {
  width: 46%;
  padding-top: calc(224 / 345 * 47%);
  background: url(../img/index/subArea_bg_01.jpg) center center/cover no-repeat;
  box-sizing: border-box;
  position: relative;
}

@media screen and (max-width: 768px) {
  .topPage .subArea_inner_cmn {
    width: 100%;
    margin-top: 5%;
  }
}

.topPage .subArea_inner_cmn_txt {
  widht: 100%;
  font-size: 38px;
  font-size: 3.8;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  color: #fff;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .topPage .subArea_inner_cmn_txt {
    font-size: 24px;
    font-size: 2.4;
  }
}

.topPage .subArea_inner_cmn a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 3px solid #00c8ac;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
}

.topPage .subArea_inner_cmn a::before {
  content: "";
  display: block;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  background: #00c8ac;
  opacity: .2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .3s;
}

@media screen and (min-width: 769px) {
  .topPage .subArea_inner_cmn a:hover::before {
    opacity: 1;
  }
  .topPage .subArea_inner_cmn a:hover .subArea_inner_cmn_txt {
    text-shadow: none;
  }
}

.topPage .subArea_inner_cmn.type--100 {
  width: 100%;
  background: url(../img/index/subArea_bg_02.jpg) center center/cover no-repeat;
  margin-top: 5%;
}

.newsList a {
  background: #fff;
  color: #231815;
  display: block;
  transition: .3s;
}

.newsList_ttl {
  font-size: 38px;
  font-size: 3.8;
  text-align: center;
  font-weight: bold;
  color: #4A575E;
}

@media screen and (max-width: 768px) {
  .newsList_ttl {
    font-size: 24px;
    font-size: 2.4;
  }
}

.newsList .date {
  font-size: 16px;
  font-size: 1.6;
  font-weight: bold;
}

.newsList .tag {
  min-width: 110px;
  background: #4A575E;
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.5em;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

.newsList .txt {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.8em;
}

.newsList_pickup {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 74px;
}

@media screen and (max-width: 768px) {
  .newsList_pickup {
    display: block;
  }
}

.newsList_pickup .date {
  font-size: 17px;
  font-size: 1.7;
  letter-spacing: -1px;
}

.newsList_pickup .txt {
  font-size: 17px;
  font-size: 1.7;
  line-height: 1.8em;
  margin-top: 16px;
  position: relative;
  z-index: 1;
}

.newsList_pickup_cmn {
  max-width: 278px;
  width: 30%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .newsList_pickup_cmn {
    width: 100%;
    max-width: none;
  }
  .newsList_pickup_cmn + .newsList_pickup_cmn {
    margin-top: 30px;
  }
}

.newsList_pickup_cmn a::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #FFF2A7;
  opacity: 0;
  transition: .3s;
  z-index: 1;
}

.newsList_pickup_cmn_fig {
  width: 100%;
  padding-top: calc(136/ 278 * 100%);
  border: 1px solid #b3b9bb;
  position: relative;
  overflow: hidden;
}

.newsList_pickup_cmn_fig img {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.newsList_pickup_cmn_sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 769px) {
  .newsList_pickup_cmn a:hover::before {
    opacity: .8;
  }
}

.newsList_list {
  border-bottom: 1px solid #b3b9bb;
  margin-top: 80px;
}

.newsList_list, .newsList_list_cmn + .newsList_list_cmn {
  border-top: 1px solid #b3b9bb;
}

.newsList_list_cmn_inner {
  max-width: 725px;
  width: 98%;
  display: flex;
  align-items: center;
  position: relative;
  left: calc(122 / 960 * 100%);
  padding: 24px;
}

@media screen and (max-width: 768px) {
  .newsList_list_cmn_inner {
    flex-wrap: wrap;
    left: 0;
    padding: 20px 16px;
  }
}

.newsList_list_cmn .tag {
  margin: 0 26px;
}

@media screen and (max-width: 768px) {
  .newsList_list_cmn .txt {
    width: 100%;
    margin-top: 16px;
  }
}

@media screen and (min-width: 769px) {
  .newsList_list_cmn a:hover {
    background: rgba(255, 242, 167, 0.8);
  }
}

.newsList_page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.newsList_page a,
.newsList_page span,
.newsList_page .next,
.newsList_page .prev {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.newsList_page a + *,
.newsList_page span + *,
.newsList_page .next + *,
.newsList_page .prev + * {
  margin-left: 12px;
}

.newsList_page a,
.newsList_page .next,
.newsList_page .prev {
  transition: .2s;
  opacity: 1;
}

@media screen and (min-width: 769px) {
  .newsList_page a:hover,
  .newsList_page .next:hover,
  .newsList_page .prev:hover {
    opacity: .8;
  }
}

.newsList_page a {
  background: #4A575E;
}

.newsList_page span {
  background: #00c8ac;
}

.newsList_page .next {
  background: url(../img/common/btn_icon_02.svg) center center/contain no-repeat;
}

.newsList_page .prev {
  background: url(../img/common/btn_icon_02.svg) center center/contain no-repeat;
  transform: rotate(180deg);
}

.newsSingle {
  padding-bottom: 70px;
}

.newsSingle .mvSingle {
  width: 100vw;
  min-height: 250px;
  background: url(../img/news/news_single_mv.jpg) center center/cover no-repeat;
  padding: 36px 0;
}

@media screen and (max-width: 768px) {
  .newsSingle .mvSingle {
    width: 100%;
    min-height: 0;
  }
}

.newsSingle .mvSingle_inner {
  max-width: 960px;
  width: 90%;
  margin: 0 auto;
}

.newsSingle .mvSingle_bread {
  border-bottom: 1px solid #4A575E;
  padding: 0 18px 4px;
}

@media screen and (max-width: 768px) {
  .newsSingle .mvSingle_bread {
    padding: 4px;
  }
}

.newsSingle .mvSingle_tag {
  margin-left: auto;
  margin-right: 0;
}

.newsSingle .mvSingle_date {
  font-size: 25px;
  font-size: 2.5;
  font-weight: bold;
}

.newsSingle .mvSingle_ttl {
  font-size: 30px;
  font-size: 3;
  line-height: 1.5em;
  font-weight: bold;
  margin-top: 16px;
}

.newsSingle .mvSingle_txtArea {
  width: 95%;
  margin: 16px auto 0;
}

.newsSingle .newsSingle_cont_inner {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.8em;
}

.newsSingle .newsSingle_cont_inner h2 {
  font-size: 30px;
  font-size: 3;
  line-height: 1.5em;
  color: #00c8ac;
  margin-top: 90px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .newsSingle .newsSingle_cont_inner h2 {
    margin-top: 40px;
  }
}

.newsSingle .newsSingle_cont_inner h3 {
  font-size: 20px;
  font-size: 2;
  line-height: 1.5em;
  border-bottom: 1px solid #4A575E;
  position: relative;
  margin-top: 44px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .newsSingle .newsSingle_cont_inner h3 {
    margin-top: 30px;
  }
}

.newsSingle .newsSingle_cont_inner h3::before {
  content: "";
  display: block;
  max-width: 135px;
  width: 20%;
  height: 3px;
  background: #00c8ac;
  position: absolute;
  bottom: -1.5px;
  left: 0;
  z-index: 1;
}

.newsSingle .newsSingle_cont_inner p {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.8em;
  margin-top: 16px;
}

.newsSingle .newsSingle_cont_inner ul li {
  list-style: disc;
  margin-left: 1.5em;
}

.newsSingle .newsSingle_cont_inner ol li {
  list-style: decimal;
  margin-left: 1.5em;
}

.newsSingle .newsSingle_cont_inner img {
  display: block;
  width: 85% !important;
  height: auto !important;
  margin: 78px auto 0;
}

.newsSingle .newsSingle_cont_inner .wp-caption {
  width: 100% !important;
}

.newsSingle .newsSingle_cont_inner .wp-caption .wp-caption-text {
  display: block;
  font-size: 16px;
  font-size: 1.6;
  text-align: center;
  color: #4A575E;
  margin-top: 14px;
}

.newsSingle .c-btnBack {
  margin-top: 140px;
}

@media screen and (max-width: 768px) {
  .newsSingle .c-btnBack {
    margin-top: 80px;
  }
}

.storyPage_inner {
  background: url(../img/story/bg.jpg) center center/contain repeat;
}

@media screen and (max-width: 768px) {
  .storyPage_inner {
    background: #fff;
  }
}

.storyPage .storySec {
  width: 90%;
  max-width: 960px;
  background: #fff;
  padding: 77px 0 94px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .storyPage .storySec {
    padding: 30px 0 40px;
  }
}

.storyPage .storySec_inner {
  width: 90%;
  max-width: 708px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .storyPage .storySec_inner {
    width: 100%;
  }
}

.storyPage .txt {
  font-size: 16px;
  font-size: 1.6;
  line-height: 2em;
  margin-top: 58px;
}

@media screen and (max-width: 768px) {
  .storyPage .txt {
    margin-top: 48px;
  }
}

.storyPage .txt strong {
  display: inline-block;
  font-size: 20px;
  font-size: 2;
  color: #00b09c;
  margin-bottom: 28px;
}

.storyPage .txt a {
  color: #00b09c;
  text-decoration: underline;
}

.storyPage .subTtl {
  font-size: 38px;
  font-size: 3.8;
  line-height: 1.5em;
  text-align: center;
  color: #4A575E;
  margin-top: 106px;
}

.storyPage .subTtl span {
  font-size: 25px;
  font-size: 2.5;
}

.storyPage .subTtl::before {
  content: "";
  display: block;
  width: 48px;
  height: 56px;
  background: url(../img/story/ttl_icon_01.svg) center center/contain no-repeat;
  margin: 0 auto 20px;
}

.storyPage .voice_ttl {
  margin-top: 94px;
  position: relative;
}

.storyPage .voice_ttl::before {
  content: "";
  display: block;
  max-width: 418px;
  width: 90%;
  height: 3px;
  background: #00c8ac;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.storyPage .voice_ttl_inner {
  width: 324px;
  background: #fff;
  font-size: 25px;
  font-size: 2.5;
  text-align: center;
  color: #4A575E;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 22px;
  position: relative;
  z-index: 1;
}

.storyPage .voice_ttl_inner .mini {
  font-size: 20px;
  font-size: 2;
  margin-left: 10px;
}

.storyPage .voice_cmn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 46px;
}

.storyPage .voice_cmn:nth-child(2n) {
  margin-top: 32px;
}

.storyPage .voice_cmn_participant {
  width: 70px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .storyPage .voice_cmn_participant {
    width: 35px;
  }
}

.storyPage .voice_cmn_participant img {
  width: 100%;
}

.storyPage .voice_cmn_participant .name {
  font-size: 13px;
  font-size: 1.3;
  line-height: 1.8em;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .storyPage .voice_cmn_participant .name {
    font-size: 10px;
    font-size: 1;
  }
}

.storyPage .voice_cmn_txtArea {
  width: calc(100% - 106px);
  background: #D6F4ED;
  padding: 24px 32px;
  border-radius: 32px;
  position: relative;
  opacity: 0;
  transform: scale(0.8);
  left: -10px;
  transition: .3s;
}

@media screen and (max-width: 768px) {
  .storyPage .voice_cmn_txtArea {
    width: calc(100% - 60px);
    padding: 24px 20px;
  }
}

.storyPage .voice_cmn_txtArea::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #D6F4ED transparent transparent;
  border-width: 10px 32px 10px 0;
  position: absolute;
  top: 28px;
  left: -30px;
}

@media screen and (max-width: 768px) {
  .storyPage .voice_cmn_txtArea::before {
    border-width: 6px 20px 6px 0;
    top: 18px;
    left: -18px;
  }
}

.storyPage .voice_cmn_catch {
  display: inline-block;
  font-size: 20px;
  font-size: 2;
  line-height: 1.5em;
  font-weight: bold;
  border-bottom: 6px solid #ACEADC;
  position: relative;
  padding: 0 10px 4px;
  margin: 0 20px;
}

.storyPage .voice_cmn_catch::before, .storyPage .voice_cmn_catch::after {
  font-size: 40px;
  font-size: 4;
  color: #00b09c;
  position: absolute;
  top: 0;
}

.storyPage .voice_cmn_catch::before {
  content: "“";
  left: -20px;
  transform: translateY(-20%);
}

.storyPage .voice_cmn_catch::after {
  content: "”";
  right: -20px;
  transform: translateY(-10%);
}

.storyPage .voice_cmn_txt {
  font-size: 16px;
  font-size: 1.6;
  line-height: 2em;
  margin-top: 14px;
}

.storyPage .voice_cmn:nth-child(4n-1), .storyPage .voice_cmn:nth-child(4n) {
  flex-direction: row-reverse;
}

.storyPage .voice_cmn:nth-child(4n-1) .voice_cmn_txtArea, .storyPage .voice_cmn:nth-child(4n) .voice_cmn_txtArea {
  background: #FFF6C2;
  left: 10px;
}

.storyPage .voice_cmn:nth-child(4n-1) .voice_cmn_txtArea::before, .storyPage .voice_cmn:nth-child(4n) .voice_cmn_txtArea::before {
  border-color: transparent transparent transparent #FFF6C2;
  border-width: 10px 0 10px 32px;
  left: auto;
  right: -30px;
}

@media screen and (max-width: 768px) {
  .storyPage .voice_cmn:nth-child(4n-1) .voice_cmn_txtArea::before, .storyPage .voice_cmn:nth-child(4n) .voice_cmn_txtArea::before {
    border-width: 6px 0 6px 20px;
    right: -18px;
  }
}

.storyPage .voice_cmn:nth-child(4n-1) .voice_cmn_catch, .storyPage .voice_cmn:nth-child(4n) .voice_cmn_catch {
  border-bottom: 6px solid #FFED84;
}

.storyPage .voice_cmn:nth-child(4n-1) .voice_cmn_catch::before, .storyPage .voice_cmn:nth-child(4n-1) .voice_cmn_catch::after, .storyPage .voice_cmn:nth-child(4n) .voice_cmn_catch::before, .storyPage .voice_cmn:nth-child(4n) .voice_cmn_catch::after {
  font-size: 40px;
  font-size: 4;
  color: #CEB63E;
}

.storyPage .voice_cmn.action .voice_cmn_txtArea {
  opacity: 1;
  transform: scale(1);
  left: 0;
}

.featurePage .feature_nav {
  width: 100%;
  background: #fff;
  padding-top: 20px;
  margin-top: 72px;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_nav {
    margin-top: 30px;
  }
}

.featurePage .feature_nav_inner {
  width: 90%;
  max-width: 960px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b3b9bb;
  padding: 0 34px 10px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_nav_inner {
    padding: 0 0 10px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .featurePage .feature_nav_cmn {
    width: 50%;
    margin-top: 12px;
  }
}

.featurePage .feature_nav_cmn a {
  font-size: 18px;
  font-size: 1.8;
  font-weight: bold;
  color: #231815;
  display: flex;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_nav_cmn a {
    width: 100%;
    font-size: 16px;
    font-size: 1.6;
    justify-content: center;
  }
}

.featurePage .feature_nav_cmn a::before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url(../img/feature/nav_icon_01.svg) center center/contain no-repeat #00c8ac;
  margin-right: 6px;
  border-radius: 50%;
}

.featurePage .feature_nav_cmn:nth-child(2n) a::before {
  background: url(../img/feature/nav_icon_02.svg) center center/contain no-repeat #00b09c;
}

.featurePage .feature_nav.active {
  position: fixed;
  left: 0;
  top: 100px;
  margin-top: 0;
  padding-bottom: 14px;
  z-index: 1000;
}

.featurePage .feature_nav.active .feature_nav_inner {
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_nav.active {
    top: 8vh;
  }
}

.featurePage .feature_nav.active + .cu-sec_inner {
  margin-top: calc(53px + 72px + 50px);
}

.featurePage .feature_cont {
  margin-top: 50px;
}

.featurePage .feature_cont_ttl {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: flex-end;
  padding: 0 32px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_cont_ttl {
    padding: 0 16px;
  }
}

.featurePage .feature_cont_ttl img {
  width: auto;
  height: 100%;
}

.featurePage .feature_cont_ttl span {
  display: inline-block;
  font-size: 15px;
  font-size: 1.5;
  color: #fff;
  font-weight: 400;
  margin-bottom: 16px;
  margin-left: 8px;
}

.featurePage .feature_cont_ttl::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #00c8ac;
  position: absolute;
  top: 0;
  left: -100%;
  transition: .3s ease;
  z-index: -1;
}

.featurePage .feature_cont_ttl.action::before {
  left: 0;
}

.featurePage .feature_cont_fig {
  position: relative;
}

.featurePage .feature_cont_fig .voice {
  display: block;
  width: auto;
  height: 74px;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_cont_fig .voice {
    height: 12vw;
  }
}

.featurePage .feature_cont_fig .voice.t-l {
  top: 36px;
  left: 52px;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_cont_fig .voice.t-l {
    top: 4vw;
    left: 4vw;
  }
}

.featurePage .feature_cont_fig .voice.b-l {
  bottom: 36px;
  left: 52px;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_cont_fig .voice.b-l {
    bottom: 4vw;
    left: 4vw;
  }
}

.featurePage .feature_cont_fig .voice.b-r {
  bottom: 36px;
  right: 52px;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_cont_fig .voice.b-r {
    bottom: 4vw;
    right: 4vw;
  }
}

.featurePage .feature_cont_txtArea {
  background: #e4f8f4;
  padding: 46px 0 58px;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_cont_txtArea {
    padding: 38px 0 38px;
  }
}

.featurePage .feature_cont_txtArea_catch {
  font-size: 38px;
  font-size: 3.8;
  text-align: center;
  color: #00b09c;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_cont_txtArea_catch {
    font-size: 28px;
    font-size: 2.8;
  }
}

.featurePage .feature_cont_txtArea_txt {
  font-size: 16px;
  font-size: 1.6;
  line-height: 2em;
  text-align: center;
  margin-top: 48px;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_cont_txtArea_txt {
    font-size: 14px;
    font-size: 1.4;
    padding: 0 10px;
    margin-top: 28px;
  }
}

.featurePage .feature_cont_txtArea_list {
  max-width: 570px;
  width: 95%;
  background: #c6f0e6;
  border-radius: 14px;
  padding: 20px 30px;
  margin: 24px auto 0;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_cont_txtArea_list {
    padding: 20px 14px;
  }
}

.featurePage .feature_cont_txtArea_list_cmn {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.8em;
  font-weight: bold;
  position: relative;
  padding-left: 20px;
}

.featurePage .feature_cont_txtArea_list_cmn + .feature_cont_txtArea_list_cmn {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .featurePage .feature_cont_txtArea_list_cmn {
    font-size: 14px;
    font-size: 1.4;
  }
}

.featurePage .feature_cont_txtArea_list_cmn::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #00c8ac;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(40%);
}

@media screen and (max-width: 768px) {
  .featurePage .feature_cont_txtArea_list_cmn::before {
    transform: translateY(26%);
  }
}

.featurePage .feature_cont_cmn:nth-child(2n) .feature_cont_ttl::before {
  background: #00b09c;
}

.featurePage .feature_cont_cmn:nth-child(2n) .feature_cont_txtArea {
  background: #CCF2E9;
}

.featurePage .feature_cont_cmn:nth-child(2n) .feature_cont_txtArea_list {
  background: #eefaf7;
}

.featurePage .feature_cont_inner {
  transition: .5s ease;
  opacity: 0;
  position: relative;
  top: -30px;
}

.featurePage .feature_cont_inner.action {
  opacity: 1;
  top: 0;
}

.featurePage .demo {
  background: #FFFDEF;
  border: 4px solid #f2e479;
  padding: 42px 0 64px;
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .featurePage .demo {
    padding: 24px 0 54px;
  }
}

.featurePage .demo_txt {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.8em;
  text-align: center;
  margin-top: 74px;
}

@media screen and (max-width: 768px) {
  .featurePage .demo_txt {
    font-size: 14px;
    font-size: 1.4;
    text-align: left;
    padding: 0 10px;
    margin-top: 40px;
  }
}

.featurePage .demo_btn {
  margin-top: 54px;
}

@media screen and (max-width: 768px) {
  .featurePage .demo_btn {
    margin-top: 36px;
  }
}

@media screen and (min-width: 769px) {
  .informationPage .info, .informationPage .info--option {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .informationPage .info, .informationPage .info--option {
    padding: 0 0 0;
  }
}

.informationPage .info--complete {
  height: 100% !important;
  padding: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 769px) {
  .informationPage .info--complete {
    padding: 0 0 30px;
  }
}

@media screen and (max-width: 768px) {
  .informationPage .info--complete {
    padding: 0;
  }
}

.informationPage .info--option {
  padding: 60px 0 152px;
}

@media screen and (max-width: 768px) {
  .informationPage .info--option {
    padding: 30px 0 75px;
  }
}

@media screen and (max-width: 768px) {
  .informationPage .info--option .text--info {
    margin-top: 20px;
  }
}

.informationPage .info--option2 {
  padding: 0 0 152px !important;
}

@media screen and (max-width: 768px) {
  .informationPage .info--option2 {
    padding: 0 0 75px !important;
  }
}

.informationPage .info_note {
  padding: 80px 0 40px;
  text-align: center;
  line-height: 1.8;
}

@media screen and (min-width: 1200px) {
  .informationPage .info_note {
    padding: 3% 0 3%;
  }
}

@media screen and (max-width: 768px) {
  .informationPage .info_note {
    padding: 30px 0;
  }
}

.informationPage .info_note--anchor {
  padding: 20px 0 80px;
  text-align: center;
  line-height: 1.8;
}

@media screen and (min-width: 1200px) {
  .informationPage .info_note--anchor {
    padding: 3% 0;
  }
}

@media screen and (max-width: 768px) {
  .informationPage .info_note--anchor {
    padding-bottom: 30px;
  }
}

.informationPage .info_anchor {
  color: #2887cd;
}

@media screen and (min-width: 769px) {
  .informationPage .info_anchor:hover {
    text-decoration: underline;
  }
}

.informationPage .info_table {
  width: 100%;
  position: relative;
  z-index: 0;
}

@media screen and (max-width: 768px) {
  .informationPage .info_table {
    overflow-x: scroll;
  }
}

.informationPage .info_table:first-child {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .informationPage .info_table:first-child {
    margin-top: 40px;
  }
}

.informationPage .info_table + .informationPage .info_table {
  margin-top: 90px;
}

@media screen and (min-width: 1200px) {
  .informationPage .info_table + .informationPage .info_table {
    margin-top: 12%;
  }
}

@media screen and (max-width: 768px) {
  .informationPage .info_table + .informationPage .info_table {
    margin-top: 75px;
  }
}

.informationPage .info_table--bg {
  width: 75%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .informationPage .info_table--bg {
    width: 100%;
  }
}

.informationPage .infoTable {
  width: 100%;
  font-size: 16px;
  table-layout: fixed;
}

@media screen and (min-width: 1200px) {
  .informationPage .infoTable {
    font-size: 1.34vw;
  }
}

@media screen and (max-width: 768px) {
  .informationPage .infoTable {
    width: 600px;
    margin: 0 auto;
  }
}

.informationPage .infoTable th,
.informationPage .infoTable td {
  padding: 12px 0;
  text-align: center;
  border: 1px solid #4a575e;
  word-break: break-all;
  vertical-align: middle;
}

@media screen and (min-width: 1200px) {
  .informationPage .infoTable th,
  .informationPage .infoTable td {
    padding: 2% 0;
  }
}

@media screen and (max-width: 768px) {
  .informationPage .infoTable th,
  .informationPage .infoTable td {
    font-size: 12px;
  }
}

.informationPage .infoTable th {
  letter-spacing: 0.1em;
}

.informationPage .infoTable_small {
  padding-left: 3px;
  display: inline-block;
}

.informationPage .infoTable_small--small {
  line-height: 1.4;
  font-size: 14px;
}

@media screen and (min-width: 1200px) {
  .informationPage .infoTable_small--small {
    font-size: 1vw;
  }
}

.informationPage .infoTable_green {
  vertical-align: middle;
  font-weight: bold;
  color: #fff;
  background-color: #00b09c;
  letter-spacing: 0.1em;
}

.informationPage .infoTable_green--deep {
  color: #fff;
  background-color: #4a575e;
}

.informationPage .infoTable_white {
  background-color: #fff;
}

.informationPage .infoTable_gray {
  background-color: #e6e6e6;
}

.informationPage .infoTable_yellow {
  font-weight: normal;
  background-color: #ffe650;
}

.informationPage .infoTable_yellow--light {
  font-weight: normal;
  background-color: #fff3a7;
}

.informationPage .infoTable_size--w60 {
  width: 60%;
}

.informationPage .infoTable_size--w40 {
  width: 40%;
}

.informationPage .infoTable_size--w33 {
  width: 33%;
}

.informationPage .infoTable_size--w20 {
  width: 20%;
}

.informationPage .infoTable_size--w15 {
  width: 15%;
}

.informationPage .pdfLink {
  display: block;
  width: 57%;
  background: #fff;
  font-size: 15px;
  font-size: 1.02489vw;
  line-height: 1.5em;
  font-weight: bold;
  text-align: center;
  color: #00b09c;
  border-radius: 20px;
  border: 2px solid #00b09c;
  margin: 12px auto 0;
  transition: .2s;
}

@media screen and (max-width: 768px) {
  .informationPage .pdfLink {
    width: 90%;
    font-size: 12px;
    font-size: 1.2;
  }
}

@media screen and (min-width: 769px) {
  .informationPage .pdfLink:hover {
    background: #00b09c;
    color: #fff;
  }
}

.informationPage .warranty {
  padding-bottom: 92px;
}

@media screen and (max-width: 768px) {
  .informationPage .warranty {
    padding-bottom: 32px;
    width: 90%;
    margin: 0 auto;
  }
}

.informationPage .warranty .c-ttl_icon {
  width: 70px;
}

.informationPage .warranty_ttl {
  font-size: 24px;
  font-size: 2.4;
  font-weight: bold;
  text-align: center;
  color: #00b09c;
}

@media screen and (max-width: 768px) {
  .informationPage .warranty_ttl {
    font-size: 20px;
    font-size: 2;
  }
}

.informationPage .warranty_ttl.type02 span {
  position: relative;
}

.informationPage .warranty_ttl.type02 span::before, .informationPage .warranty_ttl.type02 span::after {
  content: "";
  display: block;
  width: 2px;
  height: 110%;
  background: #00b09c;
  position: absolute;
  top: 4px;
  transform-origin: bottom;
}

.informationPage .warranty_ttl.type02 span::before {
  left: -14px;
  transform: rotate(-35deg);
}

.informationPage .warranty_ttl.type02 span::after {
  right: -14px;
  transform: rotate(35deg);
}

.informationPage .warranty_cmn {
  margin-top: 72px;
}

.informationPage .warranty_cmn_list {
  border: 4px solid #00c8ac;
  padding-bottom: 54px;
  margin-top: 52px;
  position: relative;
}

.informationPage .warranty_cmn_list h4 {
  font-size: 24px;
  font-size: 2.4;
  line-height: 1.5em;
  font-weight: bold;
  text-align: center;
  color: #fff;
  padding: 6px 30px;
  background: #00c8ac;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  .informationPage .warranty_cmn_list h4 {
    width: 90%;
    font-size: 18px;
    font-size: 1.8;
  }
}

.informationPage .warranty_cmn_list figure {
  max-width: 576px;
  width: 90%;
  margin: 56px auto 0;
}

.informationPage .warranty_cmn_list figure figcaption {
  line-height: 1.8em;
  margin-top: 48px;
}

.informationPage .warranty_cmn_list figure figcaption.type02 {
  text-align: center;
}

.informationPage .warranty_contact {
  background: #e4f8f4;
  border-radius: 10px;
  padding: 30px 56px 58px;
  margin-top: 52px;
}

@media screen and (max-width: 768px) {
  .informationPage .warranty_contact {
    padding: 20px 20px 32px;
    margin-top: 26px;
  }
}

.informationPage .warranty_contact_txt {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.8em;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .informationPage .warranty_contact_txt {
    font-size: 14px;
    font-size: 1.4;
    margin-top: 6px;
  }
}

.informationPage .warranty_contact_tel {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 32px;
}

@media screen and (max-width: 768px) {
  .informationPage .warranty_contact_tel {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 769px) {
  .informationPage .warranty_contact_tel {
    pointer-events: none;
    text-decoration: none;
  }
}

.informationPage .warranty_contact_tel::before {
  content: "";
  display: inline-block;
  width: 56px;
  height: 36px;
  background: url(../img/common/tel_icon.svg) center center/contain no-repeat;
  margin-right: 12px;
}

.informationPage .warranty_contact_tel a {
  font-size: 38px;
  font-size: 3.8;
  font-weight: bold;
  color: #231815;
}

@media screen and (max-width: 768px) {
  .informationPage .warranty_contact_tel a {
    font-size: 24px;
    font-size: 2.4;
  }
}

.informationPage .warranty_contact_mail {
  font-size: 24px;
  font-size: 2.4;
  font-weight: bold;
  color: #00c8ac;
  margin-top: 32px;
}

@media screen and (max-width: 768px) {
  .informationPage .warranty_contact_mail {
    font-size: 16px;
    font-size: 1.6;
    margin-top: 22px;
  }
}

.informationPage .warranty_contact_mail a {
  display: inline-block;
  color: #231815;
}

.informationPage .attention {
  width: 100%;
  text-indent: -1em;
  line-height: 1.8em;
  background: #e6e6e6;
  padding: 4px 20px;
  padding-left: calc(1em + 20px);
  margin-top: 24px;
}

@media screen and (min-width: 1200px) {
  .informationPage .attention {
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 768px) {
  .informationPage .attention {
    width: 90%;
    margin: 20px auto 0;
  }
}

.documentPage .document_list {
  margin-top: 58px;
}

.documentPage .document_list_txt {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.5em;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .documentPage .document_list_txt {
    font-size: 14px;
    font-size: 1.4;
  }
}

.documentPage .document_list_inner {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 46px;
}

@media screen and (max-width: 768px) {
  .documentPage .document_list_inner {
    display: block;
  }
}

.documentPage .document_list_cmn {
  width: calc(420 / 960 * 100%);
  padding: 40px 30px;
  background: #fff;
  transition: .3s;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .documentPage .document_list_cmn {
    width: 100%;
    padding: 0;
  }
}

@media screen and (min-width: 769px) {
  .documentPage .document_list_cmn:hover {
    background: #e4f8f4;
  }
}

@media screen and (min-width: 769px) {
  .documentPage .document_list_cmn.active:hover {
    background: #fff;
  }
}

.documentPage .document_list_cmn:nth-child(n+3) {
  margin-top: 56px;
}

@media screen and (max-width: 768px) {
  .documentPage .document_list_cmn:nth-child(n+3) {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .documentPage .document_list_cmn:nth-child(n+2) {
    margin-top: 30px;
  }
}

.documentPage .document_list_cmn_fig {
  width: 100%;
  height: 0;
  padding-top: calc(234 / 360 * 100%);
  position: relative;
  overflow: hidden;
}

.documentPage .document_list_cmn_fig img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.documentPage .document_list_cmn_name {
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
}

@media screen and (max-width: 768px) {
  .documentPage .document_list_cmn_name {
    margin-top: 20px;
  }
}

.documentPage .document_list_cmn_name input {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #231815;
  border-radius: 2px;
  margin: 0;
  pointer-events: none;
}

.documentPage .document_list_cmn_name h2 {
  font-size: 18px;
  font-size: 1.8;
  line-height: 20px;
  font-weight: bold;
  color: #231815;
  margin-left: 8px;
}

.documentPage .document_list_cmn_txt {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.8em;
  margin-top: 14px;
  margin-left: 30px;
}

.video_inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .video_inner {
    display: block;
  }
}

.video_nav {
  width: 20%;
  color: #4A575E;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .video_nav {
    width: 100%;
  }
}

.video_navHeading {
  border-bottom: 1px solid #00c8ac;
  padding-bottom: 8px;
}

.video_navList {
  margin-top: 4px;
}

.video_navLink {
  display: flex;
  color: inherit;
  transition: 0.1s;
  padding: 11px 0;
}

.video_navLink::before {
  content: "";
  display: block;
  background: #b3b9bb;
  border-radius: 50%;
  min-width: 15px;
  height: 15px;
  margin-right: 6px;
  margin-top: 3px;
}

.video_navLink:hover {
  opacity: 0.7;
}

.video_list {
  width: 67%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .video_list {
    width: 100%;
    margin-top: 30px;
  }
}

.video_item {
  width: 45%;
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .video_item {
    margin-bottom: 56px;
    width: 100%;
  }
}

.video_itemInner {
  display: block;
  transition: 0.3s;
}

.video_itemInner:hover {
  opacity: 0.8;
}

.video_itemTitle {
  color: #fff;
  background: #00c8ac;
  font-size: 15px;
  padding: 7px 12px;
}

.videoSingle {
  padding-bottom: 70px;
}

.videoSingle .videoSingle_cont_inner {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.8em;
}

.videoSingle .videoSingle_cont_inner h2 {
  font-size: 30px;
  font-size: 3;
  line-height: 1.5em;
  color: #00c8ac;
  margin-top: 90px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .videoSingle .videoSingle_cont_inner h2 {
    margin-top: 40px;
  }
}

.videoSingle .videoSingle_cont_inner h3 {
  font-size: 20px;
  font-size: 2;
  line-height: 1.5em;
  border-bottom: 1px solid #4A575E;
  position: relative;
  margin-top: 44px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .videoSingle .videoSingle_cont_inner h3 {
    margin-top: 30px;
  }
}

.videoSingle .videoSingle_cont_inner h3::before {
  content: "";
  display: block;
  max-width: 135px;
  width: 20%;
  height: 3px;
  background: #00c8ac;
  position: absolute;
  bottom: -1.5px;
  left: 0;
  z-index: 1;
}

.videoSingle .videoSingle_cont_inner p {
  font-size: 16px;
  font-size: 1.6;
  line-height: 1.8em;
  margin-top: 16px;
}

.videoSingle .videoSingle_cont_inner ul li {
  list-style: disc;
  margin-left: 1.5em;
}

.videoSingle .videoSingle_cont_inner ol li {
  list-style: decimal;
  margin-left: 1.5em;
}

.videoSingle .videoSingle_cont_inner img {
  display: block;
  width: 100% !important;
  height: auto !important;
  margin: 78px auto 0;
}

.videoSingle .videoSingle_cont_inner .wp-caption {
  width: 100% !important;
}

.videoSingle .videoSingle_cont_inner .wp-caption .wp-caption-text {
  display: block;
  font-size: 16px;
  font-size: 1.6;
  text-align: center;
  color: #4A575E;
  margin-top: 14px;
}

.videoSingle .c-btnBack {
  margin-top: 140px;
}

@media screen and (max-width: 768px) {
  .videoSingle .c-btnBack {
    margin-top: 80px;
  }
}

.videoSingle .youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.videoSingle .youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}
