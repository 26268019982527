.c-tag {
  display: block;
  width: 110px;
  background: $grayBlack;
  @include fz(16);
  line-height: 1.5em;
  text-align: center;
  font-weight: bold;
  color: $white;
}
