.topPage {
	.promise {
		@include frame;
		background-color: $blueOcean;

		@include sp {
			padding-bottom: 60px;
		}
		.c-ttl_icon {
			width: 258px;
			@include sp {
				width: 170px;
			}
		}
		.c-btnMore {
			margin-top: 90px;
			@include sp {
				margin-top: 60px;
			}
		}
	}

	.promise_box {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;

		@include pc {
			max-width: 660px;
		}

		@include pcMin {
			max-width: none;
		}
	}

	.promise_cont {
		margin-top: 80px;
		@include sp {
			margin-top: 40px;
			padding: 0 8%;
			box-sizing: border-box;
		}
	}

	.promise_list {
		& + .promise_list {
			margin-top: 10.5%;

			@include pcMin {
				margin-top: 7%;
			}

			@include sp {
				margin-top: 11%;
			}
		}
	}

	.promisePic {
		@include frame;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@include sp {
			display: block;
		}
	}

	.promisePic_box {
		width: 50%;
		height: 50%;
		position: relative;

		@include sp {
			width: 100%;
			height: auto;
			overflow: hidden;
		}
	}

	.promisePic_img {
		&--01 {
			width: 100%;
			height: 100%;
			background: url("../img/index/promise_pic01.jpg") no-repeat center;
			background-size: cover;

			@include sp {
				background: none;
			}
		}

		&--02 {
			width: 100%;
			height: 100%;
			background: url("../img/index/promise_pic02.jpg") no-repeat right center;
			background-size: cover;

			@include sp {
				background: none;
			}
		}

		&--03 {
			width: 100%;
			height: 100%;
			background: url("../img/index/promise_pic03.jpg") no-repeat left bottom;
			background-size: cover;

			@include sp {
				background: none;
			}
		}

		&--04 {
			width: 100%;
			height: 100%;
			background: url("../img/index/promise_pic04.jpg") no-repeat right center;
			background-size: cover;

			@include sp {
				background: none;
			}
		}
		&--05 {
			width: 100%;
			height: 100%;
			background: url("../img/index/promise_pic05.jpg") no-repeat center top;
			background-size: cover;

			@include sp {
				background: none;
			}
		}

		&--06 {
			width: 100%;
			height: 100%;
			background: url("../img/index/promise_pic06.jpg") no-repeat right top;
			background-size: cover;

			@include sp {
				background: none;
			}
		}

		&--07 {
			width: 100%;
			height: 100%;
			background: url("../img/index/promise_pic07.jpg") no-repeat left top;
			background-size: cover;

			@include sp {
				background: none;
			}
		}

		&--08 {
			width: 100%;
			height: 100%;
			background: url("../img/index/promise_pic08.jpg") no-repeat right top;
			background-size: cover;

			@include sp {
				background: none;
			}
		}
	}

	.promise_ballon {
		&--top {
			&--left {
				position: absolute;
				top: 15px;
				left: 20px;

				@include sp {
					width: 90%;
					top: auto;
					bottom: 15px;
				}
			}

			&--right {
				position: absolute;
				top: 15px;
				right: 20px;

				@include sp {
					width: 90%;
				}
			}
		}

		&--bottom {
			&--left {
				position: absolute;
				bottom: 15px;
				left: 20px;

				@include sp {
					width: 90%;
				}
			}
			&--right {
				position: absolute;
				bottom: 15px;
				right: 20px;

				@include sp {
					width: 90%;
				}
			}
		}
	}

	.promise_sub {
		width: 222px;
		margin: -10% auto 5%;

		@include pcMin {
			width: 18.5vw;
		}

		@include sp {
			width: 40%;
			margin: 0 auto;
		}
	}

	.promise_number {
		width: 2vw;
		margin: 0% 2% -3px 0;
		display: inline-block;

		@include sp {
			width: 4%;
			margin-right: 1%;
		}
	}

	.promiseMv {
		@include frame;
		background: url("../img/index/promise_mv.jpg") no-repeat center 0;
		background-size: cover;

		&--pattern02 {
			@include frame;
			background: url("../img/index/promise_mv02.jpg") no-repeat center 0;
			background-size: cover;
		}
	}

	.promiseSimple {
		@include frame;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;
		text-align: center;
		background-color: $green;

		@include sp {
			padding: 17% 0;
		}
	}

	.promise_label {
		padding: 2px 0;
		display: inline-block;
		position: relative;
		z-index: 1;
		padding-left: 1em;
		@include sp {
			display: inline;
			padding-left: 0;
			background: $yellow;
		}
		&:after {
			content: "";
			width: 0;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			background-color: $yellow;
			transition: width 1s ease 0.3s;
			@include sp {
				display: none;
			}
		}
	}

	.promise_cont {
		&.is-view {
			.promise_label {
				&:after {
					width: 100%;
				}
			}
		}
	}
}
