.topPage {
  .case {
    max-width: 960px;
    width: 90%;
    margin: 0 auto;

    &_cont {
      width: 100%;
      &_ttl {
        @include fz(24);
        font-weight: bold;
        text-align: center;
        color: $greenDark;
        margin-top: 42px;
        // @include sp {
        //   @include fz(18);
        //   margin-top: 32px;
        // }
      }

      &_article {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        @include sp {
          display: block;
          margin-top: 16px;
        }
        &_cmn {
          width: 47%;
          background: $greenLight;
          box-sizing: border-box;
          border: 2px solid $green;
          padding: 16px;
          @include sp {
            width: 100%;
            & + .case_cont_article_cmn {
              margin-top: 26px;
            }
          }
          &_ttl {
            @include fz(20);
            font-weight: bold;
            margin-top: 22px;
            // @include sp {
            //   @include fz(16);
            // }
          }
          &_txt {
            @include fz(16);
            line-height: 1.5em;
            margin-top: 14px;
            @include sp {
              line-height: 1.6em;
            }
            // @include sp {
            //   @include fz(12);
            //   line-height: 1.5em;
            // }
          }
        }
        &_link {
          text-align: right;
          margin-top: 26px;
          a {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            @include fz(16);
            font-weight: bold;
            color: $greenDark;
            // margin-left: auto;
            // margin-right: 0;
            &::after {
              content: "";
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url(../img/common/btn_icon.svg) center center /contain no-repeat;
              margin: 0 10px;
            }
          }
        }
      }

      &_txt {
        @include fz(16);
        line-height: 1.5em;
        text-align: center;
        margin-top: 26px;
      }
      &_list {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        @include sp {
          display: block;
        }
        &_cmn {
          width: calc(308 / 960 * 100%);
          @include fz(16);
          line-height: 2em;
          text-align: center;
          padding: 5px 0;
          @include sp {
            width: 100%;
            & + .case_cont_list_cmn {
              margin-top: 22px;
            }
          }
          &.type--green {
            background: $greenLight;
            border: 2px solid $green;
          }
          &.type--orange {
            background: $yellowLight;
            border: 2px solid $yellow;
          }
        }
      }
    }
  }
}
