.documentPage {
  .document_list {
    margin-top: 58px;
    &_txt {
      @include fz(16);
      line-height: 1.5em;
      text-align: center;
      @include sp {
        @include fz(14);
      }
    }

    &_inner {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 46px;
      @include sp {
        display: block;
      }
    }

    &_cmn {
      width: calc(420 / 960 * 100%);
      padding: 40px 30px;
      // background: $greenLight;
      background: $white;
      transition: .3s;
      cursor: pointer;
      @include sp {
        width: 100%;
        padding: 0;
      }
      @include pc {
        &:hover {
          background: $greenLight;
        }
    	}
      @include pc {
        &.active:hover {
          background: $white;
        }
    	}
      &:nth-child(n+3){
        margin-top: 56px;
        @include sp {
          margin-top: 0;
        }
      }
      &:nth-child(n+2){
        @include sp {
          margin-top: 30px;
        }
      }
      &_fig {
        width: 100%;
        height: 0;
        padding-top: calc(234 / 360 * 100%);
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          // box-shadow: 3px 3px $black;
        }
      }

      &_name {
        display: flex;
        align-items: flex-start;
        margin-top: 25px;
        @include sp {
          margin-top: 20px;
        }
        input {
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid $black;
          border-radius: 2px;
          margin: 0;
          pointer-events: none;
        }
        h2 {
          @include fz(18);
          line-height: 20px;
          font-weight: bold;
          color: $black;
          margin-left: 8px;
        }
      }
      &_txt {
        @include fz(16);
        line-height: 1.8em;
        margin-top: 14px;
        margin-left: 30px;
      }
    }
  }
}
