// @import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap');
html {
	-webkit-text-size-adjust: 100%;
}

body {
	width: 100%;
	font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium",
		"游ゴシック", "Yu Gothic", sans-serif;

	@include pc {
		overflow-x: hidden;
	}

	&.is-fixed {
		height: 100%;
		overflow: hidden;
	}
}

@media all and (-ms-high-contrast: none) {
	body {
		font-family: "メイリオ", Meiryo, sans-serif;
	}
}

main {
	position: relative;
	// padding-top: 100px;
	@include sp {
		// padding-top: 8vh;
	}
}

input,
select,
button {
	cursor: pointer;

	&:focus {
		outline: none;
	}
}

img {
	width: 100%;
	display: block;
}

sup {
	vertical-align: super;
	font-size: smaller;
}

#contact {
	@include sp {
		margin-top: 8vh;
	}
}

.section {
	width: 100%;
	padding-top: 100px;
	box-sizing: border-box;

	@include sp {
		height: auto !important;
		padding-top: 0;
	}

	&--about {
		@extend .section;
		padding: 150px 0;
		// padding: 0;
		background: url("../img/index/bg_about.jpg") repeat-y 0 0;
		background-size: 100%;

		position: relative;
		// overflow: hidden;

		@include sp {
			background: $greenLight;
			padding-bottom: 0;
		}
		&::before {
			content: "";
			width: 75%;
			height: calc(100% + 100px);
			background: $white;
			position: absolute;
			top:0;
			left: 0;
			right: 0;
			margin: 0 auto;
			@include sp {
				display: none;
				height: 100%;
			}
		}
	}

	&--subArea {
		@extend .section;
		padding: 150px 0;
		background: url("../img/index/bg_about.jpg") repeat-y 0 0;
		background-size: 100%;
		// overflow: hidden;

		@include sp {
			// background: $greenLight;
			padding: 40px 0;
		}
	}

	&--promiseMv {
		@extend .section;
		position: relative;
		// background: $green;

		&:before {
			top: 0;
			background: url("../img/index/bg_about.jpg") repeat-y 0 0;
			background-size: 100%;
		}

		&:after, &:before {
			content: "";
			width: 100%;
			height: 50%;
			position: absolute;
			left: 0;
			z-index: -1;
		}

		&:after {
			bottom: 0;
			background-color: $green;
		}
	}

	&--simple {
		@extend .section;
		background-color: $green;
	}

	&--promiseMv02 {
		@extend .section;
		position: relative;
		&:before {
			top: 0;
			background-color: $green;
		}

		&:after, &:before {
			content: "";
			width: 100%;
			height: 50%;
			position: absolute;
			left: 0;
			z-index: -1;
		}

		&:after {
			bottom: 0;
			background-color: $greenLight;
		}
	}

	&--promise {
		@extend .section;
		padding: 150px 0;
		background-color: $greenLight;
		position: relative;

		@include sp {
			padding: 40px 0;
		}

		&:before {
			top: 0;
		}

		&:after, &:before {
			content: "";
			width: 100%;
			height: 50%;
			position: absolute;
			left: 0;
			background-color: $greenLight;
			z-index: -1;
		}

		&:after {
			bottom: 0;
		}
	}

	&--promisePic,
	&--promisePic02 {
		@extend .section;
		position: relative;

		&:before {
			content: "";
			width: 100%;
			height: 50%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: $greenLight;
			z-index: -1;
		}
	}

	&--option {
		@extend .section;
		background-color: $greenLight;
		position: relative;

		&:after {
			content: "";
			width: 100%;
			height: 50%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: $greenLight;
			z-index: -1;
		}
	}

	&--case {
		@extend .section;
		position: relative;
		padding: 150px 0;
		// background-color: $greenLight;
		// position: relative;
		//
		// &:after {
		// 	content: "";
		// 	width: 100%;
		// 	height: 50%;
		// 	position: absolute;
		// 	bottom: 0;
		// 	left: 0;
		// 	background-color: $greenLight;
		// 	z-index: -1;
		// }
		@include sp {
			padding: 60px 0;
		}
	}
	&--news {
		@extend .section;
		position: relative;
		padding: 150px 0 100px;
		background-color: $greenLight;
		// position: relative;
		//
		@include sp {
			padding: 60px 0;
		}
		&:after {
			content: "";
			width: 100%;
			height: 50%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: $greenLight;
			z-index: -1;
		}
	}

	&--footer {
		@extend .section;
		background: url("../img/index/bg_footer.jpg") no-repeat top center;
		background-size: cover;
		overflow: hidden;
	}

	&--complete {
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;

		@include sp {
			height: 100vh !important;
			margin: 0 !important;
		}
	}
}

.pager {
	position: fixed;
	top: calc(50% - -2.3vw);
	left: 6%;
	z-index: 9991;
	transform: translateY(-50%);
	padding: 0;

	@include sp {
		display: none;
	}

	li {
		display: block;
		margin: 20px;

		&:first-child, &:nth-of-type(4) {
			&.is-current {
				a {
					border-color: $white;
				}
			}
		}

		a {
			display: block;
			width: 10px;
			height: 10px;
			background: $white;
			border: 2px solid $green;
			border-radius: 50%;
		}

		&.is-current {
			a {
				background: $green;
			}
		}
	}
}

.overlay {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.9);
	z-index: -9998;
	opacity: 0;

	&.is-view {
		opacity: 1;
		z-index: 9998;
	}
}

.pc {
	@include pc {
		display: block;
	}

	@include sp {
		display: none !important;
	}
}

.sp {
	@include pc {
		display: none !important;
	}

	@include sp {
		display: block;
	}
}
