@mixin pc($width: 769px) {
	@media screen and (min-width: $width) {
		@content;
	}
}

@mixin sp($width: 768px) {
	@media screen and (max-width: $width) {
		@content;
	}
}
@mixin spMini($width: 500px) {
	@media screen and (max-width: $width) {
		@content;
	}
}

@mixin pcMin($width: 1200px) {
	@media screen and (min-width: $width) {
		@content;
	}
}

@mixin frame {
	@include pc {
		width: 100vw;
		width: 100vw;
		height: $frameSizePc;
		padding-right: 35px;
		padding-left: 35px;
		box-sizing: border-box;
	}

	@include sp {
		width: 100%;
		height: calc(100% - 4vw);
	}
}

@mixin fz($font_size: 10) {
	font-size: $font_size * 1px;
	font-size: $font_size * 0.1;

}
// @function get_vw($size, $viewport: 1920) {
@function get_vw($size, $viewport: 1366) {
	$rate: 100 / $viewport;
	@return $rate * ($size - 1) * 1vw;
}

@mixin fz_vw($font_size: 10) {
	font-size: $font_size * 1px;
	font-size: get_vw($font_size);
}

@function get_vw_sp($size, $viewport: 750) {
	$rate: 100 / $viewport;
	@return $rate * ($size - 1) * 1vw;
}

@mixin sp_vw($font_size: 10) {
	@include sp {
		font-size: $font_size * 1px;
		font-size: get_vw_sp($font_size);
	}
}
