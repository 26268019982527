%text {
	@include fz_vw(20);

	@include sp {
		font-size: 16px;
	}
}

.text {
	font-size: 16px;

	@include pcMin {
		font-size: 1.34vw;
	}

	&--small {
		@include fz_vw(12);
	}

	&--about {
		display: inline-block;
		font-size: 16px;
		line-height: 2.2;
		text-align: center;
		letter-spacing: 0.075em;
		position: relative;

		@include pcMin {
			font-size: 1.35vw;
		}

		@include sp {
			line-height: 2;
			text-align: left;
		}

		span {
			@include fz(12);
			vertical-align: top;
		}
	}

	&--note {
		font-size: 15px;
		line-height: 2.8;

		@include pcMin {
			font-size: 1.25vw;
		}

		@include sp {
			font-size: 14px;
		}
	}

	&--promise {
		font-size: 16px;
		line-height: 1.2;
		text-indent: -1em;
		padding-left: 1.5em;

		@include pcMin {
			font-size: 1.34vw;
		}

		@include sp {
			line-height: 1.6;
		}
	}

	&--info {
		font-size: 16px;
		line-height: 1.95;
		text-align: center;

		@include pcMin {
			font-size: 1.34vw;
		}

		@include sp {
			padding: 0 8%;
			box-sizing: border-box;
			line-height: 1.8;
			text-align: left;
		}
	}

	&--white {
		font-size: 16px;
		line-height: 1.2;
		color: $white;

		@include pcMin {
			font-size: 1.34vw;
		}

		@include sp {
			line-height: 1.6;
		}
	}

	&--green {
		font-size: 16px;
		line-height: 1.2;
		color: $green;

		@include pcMin {
			font-size: 1.34vw;
		}

		@include sp {
			line-height: 1.6;
		}
	}
}
