.video {
  &_inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    line-height: 1.5;

    @include sp {
      display: block;
    }
  }
  &_nav {
    width: 20%;
    color: $grayBlack;
    font-size: 15px;

    @include sp {
      width: 100%;
    }
  }
  &_navHeading {
    border-bottom: 1px solid $green;
    padding-bottom: 8px;

    // @include sp {
    //   padding-bottom: 7px;
    // }
  }
  &_navList {
    margin-top: 4px;

    // @include sp {
    //   margin-top: 3px;
    // }
  }
  &_navLink {
    display: flex;
    color: inherit;
    transition: 0.1s;
    padding: 11px 0;

    // @include sp {
    //   padding: 7px 0;
    // }

    &::before {
      content: "";
      display: block;
      background: $gray;
      border-radius: 50%;
      min-width: 15px;
      height: 15px;
      margin-right: 6px;
      margin-top: 3px;

      // @include sp {
      //   min-width: 12px;
      //   height: 12px;
      //   margin-right: 5px;
      // }
    }

    &:hover {
      opacity: 0.7;
    }
  }
  &_list {
    width: 67%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include sp {
      width: 100%;
      margin-top: 30px;
    }
  }
  &_item {
    width: 45%;
    margin-bottom: 70px;

    @include sp {
      margin-bottom: 56px;
      width: 100%;
    }
  }
  &_itemInner {
    display: block;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
  &_itemTitle {
    color: $white;
    background: $green;
    font-size: 15px;
    padding: 7px 12px;

    // @include sp {
    //   font-size: 16px;
    // }
  }
}
