.topPage {
	.about {
		@include frame;

		@include pc {
			// height: auto;
		}

		@include sp {
			overflow: hidden;
		}
	}

	.about_cont {
		// height: 100%;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		background-color: $white;
		// padding: 150px 0;
		// box-sizing: border-box;
		position: relative;
		z-index: 2;

		@include sp {
			width: 100%;
			height: 100%;
			padding: 60px 0;
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-content: center;
			background: none;
		}
	}

	.about_box {
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		position: relative;

		@include sp {
			padding: 0 5%;
			box-sizing: border-box;
		}
	}

	.about_text {
		display: block;
		text-align: right;
		@include fz(12);
		@include pcMin {
			font-size: .95vw;
		}
		// position: absolute;
		// right: 0;
		// bottom: -15%;

		@include sp {
			width: 100%;
			display: block;
			position: static;
		}
	}
}
