.topPage {
	%mvFrame {
		background-color: $green;
		position: fixed;
		z-index: 9990;
	}

	.mv {
		@include frame;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		background-color: $green;
		position: relative;

		@include sp {
			height: 100vh;
			overflow: hidden;
		}
	}

	.mv_lead {
		width: 60%;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);

		@include pc {
			max-width: 1100px;
		}

		@include sp {
			width: 88%;
			top: 15%;
			transform: translateX(-50%);
		}
	}

	.mv_video {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		z-index: 1;

		@include sp {
			width: 90%;
			height: auto;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 8px solid $green;
			background: $green;
			overflow: visible;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: -9999;
			opacity: 0;
		}

		&.is-view {
			z-index: 9999;
			opacity: 1;
		}

		video {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, calc(-50% + 39px));

			@include sp {
				width: 100%;
				height: 100%;
				position: static;
				transform: translate(0, 0);
			}
		}
	}

	.mv_videoBtn {
		@include pc {
			display: none;
		}

		@include sp {
			width: 15%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
		}

		&.is-hide {
			@include sp {
				display: none;
			}
		}
	}

	.mv_videoClose {
		@include pc {
			display: none;
		}

		@include sp {
			width: 8vw;
			height: 8vw;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 8vw;
			color: $green;
			line-height: 1;
			background: $white;
			position: absolute;
			bottom: calc(100% + 8px);
			right: -8px;
		}
	}

	.mv_news {
		// width: calc(100% - (40 / 1366 * 100vw));
		// width: calc(1272 / 1366 * 100vw);

		width: 100%;
		background: #287C73;
		text-align: right;
		// padding: 0 calc(40 / 1366 * 100vw);
		position: absolute;
		// left: 50%;
		top: 100px;
		left: 0;
		// transform: translateX(-50%);
		z-index: 9998;
		@include sp {
			width: 100%;
			background: rgba(35, 24, 21, .6);
			top: 8vh;
			left: 0;
			transform: none;
			z-index: 100;
		}
		&_inner {
			height: 40px;
			// padding: 0 40px;
			padding: 0 calc(44 / 1366 * 100vw);
			box-sizing: border-box;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			@include sp {
				padding: 0 10px;
				justify-content: flex-start;
			}
		}
		p {
			@include fz(18);
			// line-height: 40px;
			font-weight: bold;
			color: $white;
			@include sp {
				@include fz(14);
			}
		}
		&_ttl {
			margin-right: 30px;
			position: relative;
			padding-bottom: 1px;
			display: flex;
			align-items: center;
			@include sp {
				display: flex;
				width: 94px;
				white-space: nowrap;
			}
			&::before {
				content: "";
				display: inline-block;
				width: 32px;
				height: 17px;
				background: url(../img/index/mv_news_icon.svg) center center /contain no-repeat;
				margin-right: 6px;
			}
		}
		&_txt {
			display: inline-block;
			span {
				border-bottom: 1px solid $white;
			}
			@include sp {
				width: calc(100% - 100px);
				text-align: left;
				line-height: 1.3em;
			}
		}
	}

	.mv_arrow {
		width: 4%;
		margin: 0 auto;
		color: $white;
		text-align: center;
		position: absolute;
		right: 0;
		bottom: 40px;
		left: 0;
		z-index: 9991;

		@include pc {
			max-width: 77px;
		}

		@include pcMin {
			max-width: none;
			width: 4vw;
		}

		@include sp {
			display: none;
		}
	}

	.mv_arrowIco {
		display: block;
		animation: arrowAnime 1s infinite alternate;
	}

	@keyframes arrowAnime {
		0% {
			transform: translateY(0);
		}

		100% {
			transform: translateY(-10px);
		}
	}

	.mv_arrowText {
		width: 100%;
		margin-bottom: 25px;
		padding-right: 2px;
		color: $white;
	}

	.mv_frame {
		&--right {
			@extend %mvFrame;
			width: 35px;
			height: 100vh;
			top: 0;
			right: 0;

			@include sp {
				display: none;
			}
		}

		&--bottom {
			@extend %mvFrame;
			width: 100vw;
			height: 35px;
			bottom: 0;
			left: 0;

			@include sp {
				display: none;
			}
		}

		&--left {
			@extend %mvFrame;
			width: 35px;
			height: 100vh;
			top: 0;
			left: 0;

			@include sp {
				display: none;
			}
		}
	}

	.mv_img {
		width: 100%;
		height: 100vh;
		background: url("../img/index/mv.jpg") no-repeat center top;
		background-size: cover;
	}

	.mv_reload {
		width: 6%;
		position: absolute;
		z-index: 3;
		right: 70px;
		bottom: 30px;
		cursor: pointer;

		@include pc {
			max-width: 110px;
			transition: opacity 0.3s ease;
		}

		@include pcMin {
			max-width: none;
			width: 6vw;
		}

		@include sp {
			width: 45%;
			margin: 0 auto;
			right: 0;
			bottom: 5.7%;
			left: 0;
		}

		&:hover {
			@include pc {
				opacity: 0.7;
			}
		}
	}
}
