.cu-mv {
  width: 100%;
  &_cont {
    height: calc(396 / 1366 * 100vw);
    background: $gray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include sp {
      // height: calc(373 / 750 * 100vw);
      height: 373px;
    }
  }
  &_ttl {
    @include fz_vw(52.8);
    text-align: center;
    color: $white;
    // text-shadow: 3px 3px 10px $black;
    text-shadow: 1px 1px 5px #231815;
    @include sp {
      @include fz(38);
    }
  }
  &_txt {
    width: 95%;
    @include fz(20);
    line-height: 1.8em;
    font-weight: 400;
    text-align: center;
    color: $white;
    margin: 0 auto;
    margin-top: calc(76 / 1366 * 100vw);
    @include sp {
      @include fz(16);
      line-height: 2em;
    }
  }

  &_breadcrumd {
    max-width: 960px;
    width: 90%;
    padding: 0 18px;
    margin: 18px auto 0;
  }
}

.storyPage .cu-mv_cont {
  background: url(../img/story/mv_bg.jpg) 84% center / cover no-repeat;
}
.newsPage .cu-mv_cont {
  background: url(../img/news/mv_bg.jpg) 14% center / cover no-repeat;
}
.featurePage .cu-mv_cont {
  background: url(../img/feature/mv_bg.jpg) center center / cover no-repeat;
}
.contactPage .cu-mv_cont {
  background: url(../img/contact/mv_bg.jpg) center center / cover no-repeat;
}
.informationPage .cu-mv_cont {
  background: url(../img/information/mv_bg.jpg) center center / cover no-repeat;
}
.documentPage .cu-mv_cont {
  background: url(../img/document/mv_bg.jpg) center center / cover no-repeat;
}
.videoPage .cu-mv_cont {
  background: url(../img/video/mv_bg.jpg) center center / cover no-repeat;
}
.videoSingle .cu-mv_cont {
  background: url(../img/video/mv_bg.jpg) center center / cover no-repeat;
}
