.cu-form {
  max-width: 870px;
  width: 100%;
  margin: 38px auto 0;
  table {
    display: block;
    tbody {
      display: block;
      tr {
        display: flex;
        justify-content: space-between;
        @include sp {
          display: block;
        }
        & + tr {
          margin-top: 4px;
        }
        th {
          display: block;
          width: 272px;
          display: flex;
          // justify-content: center;
          align-items: center;
          background: $greenDark;
          @include fz(18);
          line-height: 1.5em;
          font-weight: bold;
          color: $white;
          padding-left: 4%;
          position: relative;
          @include sp {
            width: 100%;
            padding: 20px 0;
            padding-left: 4%;
          }
          &.required::after {
            content: "";
            display: inline-block;
            width: 20%;
            padding-top: calc(28 / 56 * 20%);
            background: url(../img/common/form_required.svg) center center /contain no-repeat;
            position: absolute;
            top: 50%;
            right: 10%;
            transform: translateY(-50%);
            @include sp {
              width: 55px;
              padding-top: 27px;
              position: static;
              transform: none;
              margin-left: 20px;
            }
          }
        }
        td {
          display: block;
          // width: calc(568 / 870 * 100%);
          width: calc(100% - 272px - 20px);
          padding: 14px 0;
          // &.type-two {
          //   width: calc(568 /2 / 870 * 100%);
          // }
          @include sp {
            width: 100%;
          }
          span {
            // display: inline-block;
            display: none;
            @include fz(15);
            line-height: 1.5em;
            margin-top: 4px;
          }
          .inner {
            & + .inner {
              margin-top: 22px;
            }
          }
          .selectbox_inner {
            width: calc(148 / 568 * 100%);
            // width: calc(302 / 568 * 100%);
            position: relative;
            @include sp {
              width: 100%;
            }
            &::before {
              content: "";
              // display: block;
              display: none;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 10px 6px 0 6px;
              border-color: $grayBlack transparent transparent transparent;
              position: absolute;
              top: 50%;
              right: 12px;
              transform: translateY(-50%);
              @include sp {
                border-width: 14px 8px 0 8px;
              }
            }
          }
          .formCommon {
            @include fz(19);
            display: block;
            border: 1px solid $gray;
            border-radius: 4px;
            padding: 0 12px;
            @include sp {
              @include fz(16);
              padding: 12px;
            }
          }
          input[type="text"],
          input[type="email"],
          input[type="tel"] {
            @extend .formCommon;
            width: calc(148 / 568 * 100%);
            height: 35px;
            // border: 1px solid $black;
            // border-radius: none;
            // padding: 0 12px;
            @include sp {
              width: 100%;
              height: 40px;
            }
            &.type01 {
              width: calc(302 / 568 * 100%);
              @include sp {
                width: 100%;
              }
            }
          }
          textarea {
            @extend .formCommon;
            width: 100%;
            height: 200px;
            padding: 6px 12px;
          }
          select {
            @extend .formCommon;
            width: 100%;
            padding: 4px 12px;
            @include sp {
              width: 100%;
            }
          }
          input[type="radio"] {
            display: inline-block;
            width: 18px;
            height: 18px;
            border-color: $green;
            display: none;
          }
          .mwform-radio-field {
            display: inline-block;
            border: 1px solid $gray;
            border-radius: 4px;
            padding: 0 8px;
            margin-right: 28px;
            margin-left: 0!important;
            @include sp {
              display: block;
              margin-right: 0;
            }
            label {
              height: 100%;
              display: flex;
              align-items: center;
            }
            span {
              display: block;
              @include fz(19);
              margin-top: 0;
              position: relative;
              padding: 5px 0 5px 28px;
              @include sp {
                @include fz(16);
                padding: 12px 0 12px 32px;
              }
              &::before {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid $grayBlack;
                left: 0;
              }
              &::after, &::before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
              }
              &::after {
                width: 12px;
                height: 12px;
                background: $green;
                border-radius: 50%;
                left: 4px;
                opacity: 0;
              }
            }
            input {
              margin-top: 0;
              &:checked + span::after {
                opacity: 1;
              }
            }
          }
          #zip {
            display: inline-block;
            @include sp {
              width: calc(100% - 91px);
            }
            &+ p {
              display: none;
            }
          }
          #btnAddress {
            // display: inline-block;
            display: none;
            width: 86px;
            height: 35px;
            background: $grayBlack;
            @include fz(15);
            line-height: 35px;
            text-align: center;
            color: $white;
            border-radius: 2px;
            cursor: pointer;
            @include sp {
              height: 40px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }

  &_txt {
    @include fz(16);
    line-height: 1em;
  }

  &_inner {
    margin-top: 12px;
  }

  &_consent {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 64px;
    .check {
      display: block;
      width: 27px;
      height: 27px;
      border: 1px solid $black;
      margin-right: 20px;
      @include sp {
        margin-right: 16px;
      }
    }
    &_txt {
      @include fz(17);
      font-weight: bold;
      @include sp {
        @include fz(16);
      }
      a {
        color: $blueLink;
        font-weight: bold;
        @include pc {
          &:hover {
            text-decoration: underline;
          }
      	}
      }
    }
  }
  &_btm {
    max-width: 604px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include sp {
      flex-direction: column-reverse;
      margin-top: 20px;
    }
    input[type="submit"],
    button[type="submit"] {
      display: block;
      width: 272px;
      background: $white;
      @include fz(20);
      font-weight: bold;
      text-align: center;
      color: $green;
      border: 3px solid $green;
      border-radius: 0;
      padding: 18px 0;
      margin: 62px auto 0;
      transition: .3s;
      @include pc {
        &:hover {
          background: $green;
          color:$white;
        }
    	}
      & + input[type="submit"] {
        margin-left: 30px;
      }
      &.second {
        margin-top: 20px;
      }
    }
  }

  &.type-mini {
    max-width: 604px;
    margin-top: 62px;
    td {
      input[type="text"],
      input[type="email"],
      input[type="tel"] {
        &.type01 {
          width: 100%;
        }
      }
    }
  }
}
.cu-form_top {
  &_txt {
    width: 95%;
    @include fz(16);
    line-height: 1.8em;
    margin: 0 auto;
    @include sp {
      @include fz(14);
    }
    &.type02 {
      margin-top: 40px;
      @include sp {
        margin-top: 30px;
      }
    }
  }
  &_telArea {
    background: $greenLight;
    padding: 42px 0 52px;
    margin-top: 20px;
    @include sp {
      padding: 22px 0 32px;
    }
    &_inner {
      max-width: 570px;
      width: 95%;
      margin: 0 auto;
      @include sp {
        width: 85%;
      }
    }
    &_tel {
      display: flex;
      align-items: center;
      &::before {
        content: "";
        display: inline-block;
        width: 56px;
        height: 36px;
        background: url(../img/common/tel_icon.svg) center center /contain no-repeat;
        margin-right: 20px;
      }
      a {
        @include fz(38);
        font-weight: bold;
        color: $black;
        // position: relative;
        @include sp {
          @include fz(24);
        }
        @include pc {
    			pointer-events: none;
    			text-decoration: none;
    		}
      }
    }
    &_txt {
      @include fz(20);
      line-height: 1em;
      margin-top: 26px;
      @include sp {
        @include fz(14);
        line-height: 1.5em;
        margin-top: 14px;
      }
      & + .contact_top_telArea_txt {
        margin-top: 22px;
      }
    }
  }

  &_stage {
    max-width: 790px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 80px auto 0;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: $greenDark;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &_cmn {
      width: 15px;
      height: 15px;
      background: $greenDark;
      border-radius: 50%;
      border: 1px solid $greenDark;
      position: relative;
      span {
        @include fz(16);
        white-space: nowrap;
        line-height: 1.5em;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(-100% - 10px));
      }
      // &:nth-of-type(1)::after{
      //   content: "情報の入力";
      // }
      // &:nth-of-type(2)::after{
      //   content: "内容の確認";
      // }
      // &:nth-of-type(3)::after{
      //   content: "完了";
      // }
    }
  }

}
.mw_wp_form_input {
  #btnAddress {
    display: inline-block!important;
  }
  tr td span{
    display: inline-block!important;
  }
  .selectbox_inner::before {
    display: block!important;
  }
}

.display-input.none {
  display: none;
}
.display-complete.none {
  display: none;
}
// #autozip {
//   display: none!important;
// }
// .mwform-radio-field {
//   display: block;
//   border: 1px solid $grayBlack;
//   border-radius: 4px;
//   padding: 0 12px;
//   label {
//     height: 100%;
//     display: flex;
//     align-items: center;
//   }
//   span {
//     @include fz(19);
//     margin-top: 0;
//   }
// }
// .mw_wp_form .horizontal-item {
//   margin-left: auto!important;
//   margin-right: 24px;
//   margin-top: 12px;
// }
