.cu-breadcrumbs {
  @include fz(14);
  display: flex;
  align-items: center;
  @include sp {
    overflow: hidden;
  }
  span[property="name"] {
    display: inline-block;
    padding: 0;
    margin-top: -3px;
    vertical-align: middle;
    max-width: 25em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include sp {
      max-width: 8em;
    }
  }
  a {
    color: $black;
  }
  .home {
    span {
      display: none;
    }
    display: inline-block;
    width: 14px;
    height: 15px;
    background: url(../img/common/breadcrumbs_icon.svg) center center /contain no-repeat;
  }

  .direction {
    display: inline-block;
    width: 9px;
    height: 12px;
    background: url(../img/common/breadcrumbs_direction.svg) center center /contain no-repeat;
    margin: 0 8px;
  }
}
