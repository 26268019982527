.topPage {
  .news {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;

    .c-btnMore {
      margin-top: 120px;
      @include sp {
        margin-top: 60px;
      }
    }

    &_list {
      width: 90%;
      max-width: 960px;
      border-bottom: 1px solid $gray;
      margin: 92px auto 0;
      @include sp {
        margin-top: 40px;
      }
      &, &_cmn + .news_list_cmn {
        border-top: 1px solid $gray;
      }
      &_cmn {
        &_inner {
          background: $greenLight;
          display: flex;
          align-items: center;
          color: $black;
          padding: 24px calc(116 / 960 * 100%);
          transition: .2s;
          @include sp {
            // max-width: 446px;
            // width: 90%;
            margin: 0 auto;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 16px 4vw 24px;
          }
          @include pc {
            &:hover {
              background: $yellow02;
            }
        	}
        }
      }
      &_date {
        @include fz(16);
        line-height: 1em;
      }
      &_tag {
        width: 110px;
        @include fz(16);
        line-height: 1.8em;
        text-align: center;
        color: $white;
        background: $grayBlack;
        margin: 0 22px;
      }
      &_txt {
        @include sp {
          width: 100%;
          margin-top: 16px;
        }
      }
    }
  }
}
