.wrap {
	width: 75%;
	margin: 0 auto;

	@include sp {
		width: 100%;
	}

	&--info {
		padding: 0 0 125px;
		position: relative;

		@include sp {
			padding: 0 0 90px;
		}

		&, &:after {
			width: 100%;
			background-color: $greenLight;
		}

		&:after {
			content: "";
			height: 35px;
			position: absolute;
			top: 100%;
			left: 0;

			@include pcMin {
				height: 5%;
			}

			@include sp {
				display: none;
			}
		}
	}
}
